import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../assets/css/consumerPhoneCarousel.css";

import desktopFrame from '../assets/images/consumer_landing/iPhoneFrame.avif';
import mobileFrame from '../assets/images/consumer_landing/iOS_Mobile.avif';
import mockupImage from '../assets/images/consumer_landing/mockup_img.webp';

import beauty from '../assets/images/consumer_landing/beauty.png';
import strength from '../assets/images/consumer_landing/strength.png';
import travel from '../assets/images/consumer_landing/travel.png';
import theatremask from '../assets/images/consumer_landing/theatremask.png';
import productivity from '../assets/images/consumer_landing/productivity.png';
import mentalhealth from '../assets/images/consumer_landing/mentalhealth.png';

gsap.registerPlugin(ScrollTrigger);

const HeroSectionAnimation = () => {
    const frameRef = useRef(null);
    const contentRef = useRef(null);
    const [phoneFrame, setPhoneFrame] = useState(desktopFrame);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const updateSize = () => {
            setIsMobile(window.innerWidth <= 768);
            setPhoneFrame(window.innerWidth <= 768 ? mobileFrame : desktopFrame);
            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 1000); // Small delay ensures the layout is ready
        };

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);


    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 1000); // Small delay ensures the layout is ready
    }, []);


    useEffect(() => {
        if (!frameRef.current || !contentRef.current) return;

        const content = contentRef.current;
        const frame = frameRef.current;

        const isMobile = window.innerWidth <= 768;
        const scrubValue = isMobile ? 3 : 1.5; // 🚀 Make scrolling slower on mobile
        const startPosition = isMobile ? "center center" : "center center"; // Adjust start point for mobile
        const endPosition = isMobile ? "bottom center" : "bottom center"; // Extend scroll effect

        setTimeout(() => {
            requestAnimationFrame(() => {
                gsap.to(content, {
                    y: () => -(content.scrollHeight - frame.clientHeight),
                    ease: "none",
                    scrollTrigger: {
                        trigger: frame,
                        start: startPosition,
                        end: endPosition,
                        scrub: scrubValue,
                        pin: false,
                    },
                });
            });
        }, 1000); // Small delay ensures the layout is ready

    }, []);

    return (
        <div className="hero-17-mockup-image-holder-2">

            {/* ✅ Hide these elements on mobile */}
            {!isMobile && (
                <>
                    <div className="beauty"><img src={beauty} alt="Vasi" /></div>
                    <div className="strength"><img src={strength} alt="Vasi" /></div>
                    <div className="travel"><img src={travel} alt="Vasi" /></div>
                    <div className="theatremask"><img src={theatremask} alt="Vasi" /></div>
                    <div className="productivity"><img src={productivity} alt="Vasi" /></div>
                    <div className="mentalhealth"><img src={mentalhealth} alt="Vasi" /></div>
                    <div className="mentalhealth-right"><img src={mentalhealth} alt="Vasi" /></div>
                </>
            )}

            <div className="hero-mockup-main-holder">
                {/* Pin the iPhone frame so it doesn't move */}
                <div className="home-mockup-holder hero fixed-frame" ref={frameRef}>
                    {/* iPhone Frame */}
                    <img loading="lazy" src={phoneFrame} alt="iPhone Frame" className="hero-iphone-mockup-image" />

                    {/* Scrollable Content Inside iPhone */}
                    <div className="main-image-parent">
                        <div ref={contentRef} className="main-app-image-holder-2">
                            <img loading="lazy" src={mockupImage} alt="Mockup Content" className="main-app-images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSectionAnimation;
