import React, {useState} from "react";
import Slider from "react-slick";
import "../assets/css/HowCanLearnSection.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import how_can_learn_1 from '../assets/images/consumer_landing/how_can_learn_1.png';
import how_can_learn_2 from '../assets/images/consumer_landing/how_can_learn_2.png';
import how_can_learn_3 from '../assets/images/consumer_landing/how_can_learn_3.png';

const HowCanLearnSection = () => {
    const cards = [
        { title: "Bite – sized knowledge in seconds", text: "Why waste days figuring it out? Get instant actionable knowledge from real experiences – learn fast, act faster.", img: how_can_learn_1 },
        { title: "Your knowledge power bank", text: "Explore a variety of content, all curated by a community who is all about helping others with their experiences.", img: how_can_learn_2 },
        { title: "Your personal assistant", text: "Your all-in-one personal assistant lets you explore, ask, and remind you to take action.", img: how_can_learn_3 },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1.06, // Shows 1 full card + 10% of the next one
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,
        centerPadding: "5%",
        afterChange: (index) => setActiveIndex(index), // ✅ Track active slide
        responsive: [
            { breakpoint: 768, settings: { slidesToShow: 1.06, centerPadding: "5%" } },
            { breakpoint: 1024, settings: { slidesToShow: 1.3, centerPadding: "15%" } },
        ],
    };

    return (
        <section className="how-can-learn-section text-center">
            <div className="section-title">How You Can Learn?</div>
            <p className="section-subtitle">
                Explore, ask, and take action—your go-to space for knowledge, insights, and assistance!
            </p>

            <div className="container mt-4" style={{position:"relative"}}>
                <Slider {...settings}>
                    {cards.map((card, index) => (
                        <div key={index} className="carousel-card">
                            <div className="info-card d-flex">
                                <div className="text-left flex-grow-1">
                                    <h3 className="info-title">{card.title}</h3>
                                    <p className="info-text">{card.text}</p>
                                </div>
                                <div className="info-image-container">
                                    <img loading="lazy" src={card.img} alt={card.title} className="img-fluid info-image" />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

                {/* Carousel Indicators */}
                <div className="carousel-indicators">
                    {cards.map((_, index) => (
                        <span
                            key={index}
                            className={`indicator ${activeIndex % cards.length === index ? "active" : ""}`}
                            onClick={() => setActiveIndex(index)} // ✅ Clicking moves to slide
                        ></span>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default HowCanLearnSection;
