import React from "react";
import '../../assets/css/CreateShareEarn.css';

import editor_tool from "../../assets/images/create-share-earn/editor_tool.png"
import dot_creation from "../../assets/images/create-share-earn/dot_creation.png"
import reminder from "../../assets/images/create-share-earn/reminder.png"

const CreateShareEarn = () => {
  return (
    <>
      <div className="create-share-earn-hero">
        <div className="main-title">🚀 Create, Share & Earn – Your Guide to Success on VaSi</div>
        <div className="title-underline"></div>
      </div>

      <div className="create-share-earn-intro">
        <p>Your knowledge and experiences have value—VaSi helps you transform them into income. Whether it's life lessons, professional insights, or unique skills, you can create and sell high-quality Dots that inspire and educate others.</p>
        <p>This guide will walk you through everything you need to know to craft impactful Dots, attract buyers, and maximize your earnings. Follow these best practices to make the most of VaSi and start earning today!</p>
      </div>

      <div className="create-share-earn-container">
        <div className="create-share-earn-section">
          <div className="create-share-earn-highlight">
            <h4>Why Share on VaSi?</h4>
            <div className="create-share-earn-features-grid">
              {[
                { title: "💰 Double Earnings", description: "Earn from creation rewards and Dot sales" },
                { title: "🎯 Reach Audience", description: "Connect with people seeking real experiences" },
                { title: "💡 Share Knowledge", description: "Help others learn from your experiences" },
                { title: "🌱 Build Presence", description: "Establish yourself as a thought leader" },
              ].map((feature, index) => (
                <div key={index} className="create-share-earn-feature-card">
                  <h4>{feature.title}</h4>
                  <div className="feature-desc">{feature.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="create-share-earn-section">
          <h2>📝 Creating a Qualifying Dot</h2>
          <div className="create-share-earn-card">
            <h3>Essential Requirements:</h3>
            <ul>
              <li><strong>Original Content:</strong> Must be written by you, based on real experiences</li>
              <li><strong>No AI Generation:</strong> All content should be human-written</li>
              <li><strong>Value Delivery:</strong> Include actionable insights, tips, or learnings</li>
              <li><strong>Professional Formatting:</strong> Use our editor tools effectively</li>
              <li><strong>Visual Appeal:</strong> Include a relevant cover image</li>
            </ul>
            <div className="create-share-earn-warning">
              <p><strong>Important:</strong> Dots that are AI-generated or lack original insights will not qualify for the reward program.</p>
            </div>
          </div>
        </div>

        <div className="create-share-earn-section editor-tool">
          <h2>🛠️ Using Our Editor Tools</h2>
          <p className="earn-desc">Create professional-looking Dots with our powerful editing tools:</p>
          {[
            {
              title: "Text Formatting",
              imgSrc: editor_tool,
              steps: [
                "Use headings to organize your content",
                "Add bold and italic text for emphasis",
                "Create bullet points and numbered lists",
                "Insert inline images to provide references for your writing",
              ],
            },
            {
              title: "Rich Media",
              imgSrc: dot_creation,
              steps: [
                "Add images, Videos and Audio",
                "Insert links to additional resources",
                "Color the parts of content to make them prominent",
              ],
            }, {
              title: "Add Reminders",
              imgSrc: reminder,
              steps: [
                "Add images, Videos and Audio",
                "Insert links to additional resources",
                "Color the parts of content to make them prominent",
              ],
            },
          ].map((tool, index) => (
            <div key={index} className="create-share-earn-tool-preview">
              <h3>{tool.title}</h3>
              <img src={tool.imgSrc} alt={`${tool.title} Screenshot`} />
              <ol>
                {tool.steps.map((step, stepIndex) => (
                  <li key={stepIndex}>{step}</li>
                ))}
              </ol>
            </div>
          ))}
        </div>

        <div className="create-share-earn-section">
          <h2>💡 Examples of Outstanding Dots</h2>
          {[
            {
              title: "Life Experiences & Lessons",
              subTitle: '"How I Overcame Procrastination: A 30-Day Journey"',
              details: [
                "Personal story with real challenges and solutions",
                "Day-by-day breakdown of actions taken",
                "Before/after results with metrics",
                "Practical tips for readers to implement",
              ],
            },
            {
              title: "Professional Development",
              subTitle: '"10 Negotiation Techniques That Doubled My Salary"',
              details: [
                "Real-world scenarios and outcomes",
                "Specific phrases and strategies used",
                "Common pitfalls to avoid",
                "Templates for readers to use",
              ],
            },
            {
              title: "Travel & Culture",
              subTitle: '"Hidden Gems of Tokyo: A Local\'s Guide to Budget Travel"',
              details: [
                "Insider knowledge from living experience",
                "Detailed cost breakdowns",
                "Season-specific recommendations",
                "Interactive map with highlighted locations",
              ],
            },
          ].map((item, index) => (
            <div key={index} className="create-share-earn-card dot-details">
              <h3>{item.title}</h3>
              <div className="create-share-earn-dots">
                <h4>{item.subTitle}</h4>
                <ul>
                  {item.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{detail}</li>
                  ))}
                </ul>
                {/* <img src="/api/placeholder/600/200" alt={`dots for ${item.title}`} /> */}
              </div>
            </div>
          ))}
        </div>


        <div className="create-share-earn-section">
          <h2>📊 Content Creation Checklist</h2>
          {[
            {
              title: "Before You Start:",
              details: [
                "Choose a topic you have personal experience with",
                "Outline your main points and key takeaways",
                "Gather any supporting materials (images, videos, audio, etc.)",
              ],
            },
            {
              title: "While Writing:",
              details: [
                "Start with a compelling introduction",
                "Use headers to organize your content",
                "Include real examples and specific details",
                "Add relevant images or diagram",
                "Format text for easy reading",
              ],
            },
            {
              title: "Before Publishing:",
              details: [
                "Review for clarity and completeness",
                "Check formatting and visual appeal",
                "Verify all claims and information",
              ],
            },
          ].map((item, index) => (
            <div key={index} className="create-share-earn-card content-creation">
              <h3>{item.title}</h3>
              <div className="create-share-earn-dots">
                <ol>
                  {item.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{detail}</li>
                  ))}
                </ol>
                {/* <img src="/api/placeholder/600/200" alt={`dots for ${item.title}`} /> */}
              </div>
            </div>
          ))}
        </div>


        <div className="create-share-earn-section">
          <h2>❌ What to Avoid</h2>
          <div className="create-share-earn-warning what-to-avoid">
            <h3>Common Mistakes:</h3>
            <ul>
              <li><span>AI-Generated Content:</span> Using AI writing tools for content creation</li>
              <li><span>Generic Information:</span> Writing without personal insights or experiences</li>
              <li><span>Poor Formatting:</span> Not using the editor tools effectively</li>
              <li><span>Lack of Value:</span> Creating content without clear takeaways</li>
              <li><span>Missing Visuals:</span> Not including relevant images or diagrams</li>
            </ul>
          </div>
        </div>

        <div className="create-share-earn-section">
          <div className="create-share-earn-highlight">
            <h2>🚀 Ready to Start Creating?</h2>
            <p>Your experiences are valuable, and people are waiting to learn from them. Start creating your first Dot today and join our community of knowledge sharers!</p>

          </div>
        </div>

        <div className="create-share-earn-section">
          <h2>Need Help?</h2>
          <div className="create-share-earn-card">
            <p>If you have any questions about creating Dots or the reward program, reach out to our support team:</p>
            <ul>
              <li><a href="mailto:hello@learnee.ai">hello@learnee.ai</a></li>
              <li>In-app Contact Support</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateShareEarn;
