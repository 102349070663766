import React, { useEffect, useState, useRef } from 'react';
import ReactGA from "react-ga4";
import NavBar from './NavBar'



import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'
import '../assets/css/landingpage.css';
import '../assets/css/creators.css';
//import LandingPageFooter from './LandingPageFooter';
//import DotsImagesMobileCarousel from './BlurredImages';


import header_right from '../assets/images/landing_v2/header-right.png';

//import header_right_mobile from '../assets/images/landing_v2/header-right-mobile.png';




import piggy_bank from '../assets/images/landing_v2/piggy-bank.gif';
import face_mask from '../assets/images/landing_v2/face-mask.gif';

import recipe_book from '../assets/images/landing_v2/recipe-book.gif';
import alarm_clock from '../assets/images/landing_v2/alarm-clock.gif';

import family_time from '../assets/images/landing_v2/family-time.png';
import books_stack from '../assets/images/landing_v2/books-stack.png';
import travel_bag from '../assets/images/landing_v2/travel-bag.png';
import language_learning from '../assets/images/landing_v2/language-learning.png';
import friendly_ghost from '../assets/images/landing_v2/friendly-ghost.png';

import fluent_icon from '../assets/images/landing_v2/fluent_icon.png';
import arcticons_icon from '../assets/images/landing_v2/arcticons_icon.png';
import streamline_business_icon from '../assets/images/landing_v2/streamline_business_icon.png';


import suitcase from '../assets/images/landing_v2/suitcase.png';
import positive_dynamic from '../assets/images/landing_v2/positive_dynamic.png';
import signpost from '../assets/images/landing_v2/signpost.png';
import romance from '../assets/images/landing_v2/romance.png';
import rainbow from '../assets/images/landing_v2/rainbow.png';
import classroom from '../assets/images/landing_v2/classroom.png';

// import section_3_1 from '../assets/images/landing_v2/section_3_1.png';
// import section_3_2 from '../assets/images/landing_v2/section_3_2.png';

//import section_4_right from '../assets/images/landing_v2/section_4_right.png';

//import right_mobile from '../assets/images/landing_v2/right_mobile.png';
import LandingPageFooterCosumer from './LandingPageFooterCosumer';
import HowCanCreateSection from './HowCanCreateSection';
import ExploreDotCategory from './ExploreDotCategory';
import FAQCreators from './FAQCreators';





//import TextSlider from './TextSlider';

// App Component
function CreatorsPage() {

    const [selectedCategory, setSelectedCategory] = useState("");
    const exploreCategoryRef = useRef(null); // Reference to scroll to
    const App_url = 'https://learnee.page.link/rniX'

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    const gridItems = [
        { id: 1, className: "piggy-bank", type: "gif", src: piggy_bank, alt: "Piggy Bank" },
        { id: 2, className: "books-stack", type: "image", src: books_stack, alt: "Books Stack" },
        { id: 3, className: "face-mask", type: "gif", src: face_mask, alt: "Face Mask GIF" },
        { id: 4, className: "travel-bag", type: "image", src: travel_bag, alt: "Travel Bag" },
        { id: 5, className: "recipe-book", type: "gif", src: recipe_book, alt: "Recipe Book GIF" },
        { id: 6, className: "language-learning", type: "image", src: language_learning, alt: "Language Learning" },
        { id: 7, className: "friendly-ghost", type: "image", src: friendly_ghost, alt: "Friendly Ghost GIF" },
        { id: 8, className: "family-time", type: "image", src: family_time, alt: "Family Time" },
        { id: 9, className: "alarm-clock", type: "gif", src: alarm_clock, alt: "Alarm Clock GIF" },
    ];



    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.classList.remove('hidden');
        }
    }, []);


    const handleButtonClick = (action, label) => {
        // Track the button click event
        ReactGA.event({
            category: "Button",
            action: action, //"Download Now",
            label: label //"VaSi App Link",
        });
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        //console.log(selectedCategory);
        // Smooth scroll to category section
        setTimeout(() => {
            exploreCategoryRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 300);
    };


    return (
        <div className="askvasi-home landing-page download-page creator-landing-page" id='askvasi-landing'>
            {/* Navbar */}
            <NavBar />



            {/* Page 6 and beyond */}
            <div className="page-wrapper slide-up-overlay-slide-6 page-6 active" id="page-6">
                {/* Container for the two columns */}

                <div className="hero-section-container">
                    {/* Left Column: Overlay content */}
                    <div className="overlay-content-hero-section">

                        <div className='hero-title'><div className='hero-title-1'>Turn Your<br /><i>Knowledge</i><br />Into Income</div>
                        </div>
                        <p>Create and sell Dots on <span className='self-help-btn' onClick={() => handleCategoryClick("Self-Help")}>Self-Help</span>, <span className='nutrition-btn' onClick={() => handleCategoryClick("Productivity")}>Productivity</span>,  <span className='fitness-btn' onClick={() => handleCategoryClick("Self-help books")}>Book-summaries</span>, and more—share your knowledge with the world and get paid for it!</p>

                        <div className='hero-section-btn'>
                            <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Start Creating", "VaSi App Link")} className="cta-button">Start Creating</button></a>
                        </div>

                    </div>

                    {/* Right Column: Blurred images container blurred-images-container-hero-section*/}
                    <div className="blurred-images-container-hero-section">
                        <div className="image-5">
                            <img className='' src={header_right} loading="lazy" alt="Blurred Dots 5" />
                            {/* <img className='header-right-mobile' src={header_right_mobile} loading="lazy" alt="Blurred Dots 5" /> */}
                        </div>
                    </div>

                </div>
            </div>

            <div className='container'>
                {/* Steps Section */}
                <div className="how-it-works-section">
                    <div className="section-title">How you can Create?</div>
                    <p>Follow these 3 simple steps and start sharing your experiences.</p>

                    <HowCanCreateSection />

                    <div className='how-it-works-section-btn'>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Start Creating", "VaSi App Link")} className="cta-button">Start Creating</button></a>
                    </div>
                </div>


                <div className="beyond-chat-section">
                    <div className="content-container">
                        <div className="image-container">

                            <div className="unique-grid-container">
                                {gridItems.map((item) => (
                                    <div key={item.id} className={`unique-grid-item ${item.className}`}>
                                        {item.type === "gif" ? (
                                            <img src={item.src} alt={item.alt} className="gif-image" />
                                        ) : (
                                            <img src={item.src} alt={item.alt} />
                                        )}
                                    </div>
                                ))}
                            </div>


                        </div>
                        <div className="text-container">
                            <div className="beyond-title">Sell Anything – Your Words, Your Way</div>
                            <p>
                                From guides to personal stories, insights to how-to lists—turn any written experience into value.
                            </p>
                            <ul className="custom-list">
                                <li><img width={24} src={suitcase} alt="Binoculars" /> Travel Tips & Itineraries</li>
                                <li><img width={24} src={positive_dynamic} alt="Binoculars" /> Life Lessons & Personal Growth</li>
                                <li><img width={24} src={classroom} alt="Binoculars" /> Career & Business Advice</li>
                                <li><img width={24} src={signpost} alt="Binoculars" /> How-To Guides & Tutorials </li>
                                <li><img width={24} src={rainbow} alt="Binoculars" /> Journals & Reflections</li>
                                <li><img width={24} src={romance} alt="Binoculars" /> Relationship Advice</li>
                            </ul>
                            {/* <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Get Started", "VaSi App Link")} className="cta-button">Get Started</button>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="download-page consumer-page" ref={exploreCategoryRef}>
                <section className="explore-dots-section">
                    <div className="section-title">Find what others are sharing</div>
                    <p className="section-subtitle">
                        Our marketplace isn’t limited to one field—tap and find inspiration on a wide array of topics:
                    </p>

                    <ExploreDotCategory selectedCategoryProps={selectedCategory} exploreCategoryRef={exploreCategoryRef} />

                    <div className='best-lessons-btn'>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Start Exploring", "VaSi App Link")} className="cta-button">
                                Start Exploring
                            </button>
                        </a>
                    </div>
                </section>
            </div>

            {/* <div className="beyond-chat-section section-3">
                <div className="content-container">
                    <div className="image-container">
                        <div className="beyond-title beyond-title-1">You don’t need to be an author—just have a story to tell.</div>
                        <div>
                            <img loading="lazy"
                                src={section_3_1}
                                alt="VaSi App Preview"
                                className="beyond-chat-image"
                            />
                        </div>

                    </div>
                    <div className="text-container">
                        <div className="beyond-title beyond-title-2">Share your experiences, insights, or lessons. We’ll help you sell it effortlessly.</div>
                        <div>
                            <img loading="lazy"
                                src={section_3_2}
                                alt="VaSi App Preview"
                                className="beyond-chat-image"
                            />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Testimonials Section */}
            <div className="container how-to-create text-center">
                <div className="advice-heading mt-3">Join our community of Thinkers and start growing</div>
                {/* <p className="advice-paragraph advice-paragraph-mobile mt-2">Empowering you to act with confidence.</p> */}
                <div className="d-flex justify-content-center gap-5 my-4 community-section">
                    <div>
                        <div><img className='fire-icon' src={fluent_icon} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">100K+</span>
                        <p className="testimonials-text">Users learned and benefited so far</p>
                    </div>
                    <div>
                        <div><img className='list-icon' src={arcticons_icon} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">10K+</span>
                        <p className="testimonials-text">Collection of Dots on different categories</p>
                    </div>
                    <div>
                        <div><img className='list-icon' src={streamline_business_icon} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">$100/week</span>
                        <p className="testimonials-text">Avg. earnings of a writer*</p>
                    </div>
                </div>
            </div>

            <div className='container how-to-create'>

                <div className="explore-dots-section creator-section-bottom">

                    <div className="section-title">Put your valuable experiences in people’s hands today!</div>
                    <p>Empower curious minds with bite-sized wisdom from real experiences and insights. Learn, apply, and grow with VaSi’s just-in-time knowledge!</p>

                    <div className="carousel-container">

                        <div className='best-lessons-btn'>
                            <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Start Creating", "VaSi App Link")} className="cta-button">
                                    Start Creating
                                </button>
                            </a>
                        </div>

                    </div>
                    <div className='footer_bg_middle'></div>

                </div>

            </div>


            <FAQCreators />


            <LandingPageFooterCosumer />



        </div>

    );
}
export default CreatorsPage;