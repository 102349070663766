import img_IN from "../assets/images/flags/India.png"
import img_US from "../assets/images/flags/US.png"
import { UseAuth } from "../hooks/useAuth";
import { sha256 } from 'js-sha256';
import blogPosts from "../data/blogPosts";

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let months = date.getMonth() + 1;
  let dates = date.getDate();
  let fullYrs = date.getFullYear();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = hours + ':' + minutes + ' ' + ampm + ' ' + months + '/' + dates + '/' + fullYrs;
  return strTime;
}


export function formatWithMonthName(created_data) {
  const date = new Date(created_data);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  //let months = date.getMonth() + 1;
  let monthsName = date.toLocaleString('default', { month: 'short' });
  let dates = date.getDate();
  let fullYrs = date.getFullYear();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = dates + ' ' + monthsName + ', ' + fullYrs + ' ' + hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


export function countryCode(code) {
  const country_list = [
    { id: '91', name: 'IN', 'img': img_IN },
    { id: '1', name: 'US', 'img': img_US },
    { id: '011', name: 'CAN', 'img': img_US },
  ]

  var country_code = country_list.find(key => key.id === code)
  //console.log(country_code);
  if (typeof country_code == 'undefined') {
    country_code =
      { id: '91', name: 'IN', 'img': img_IN }
  }
  return country_code;
}

export function freeDots(priceData) {
  // Check if priceData is 75, set it to 0.99 if true
  if (priceData === 75) {
    priceData = 0.99;
  }

  const price = (priceData === 0)
    ? 'Free'
    : <span><i className="fa fa-dollar-sign" aria-hidden="true"></i> {kFormatter(priceData)}</span>;
  return price;
}


export function countryList() {
  const options = [{ id: "+91", title: "IN", img: img_IN }, { id: "+1", title: "US", img: img_US }];
  return options;
}


export function userId() {
  const auth = UseAuth();
  const user_phone = auth.user.phoneNumber.substring(3);
  const platform = 1;
  const user_id = sha256(user_phone + platform);
  return user_id;
}

//    export function kFormatter(num) {
//     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num).toFixed(2)
// }

export function kFormatter(num) {
  const absNum = Math.abs(num);
  const sign = Math.sign(num);

  if (absNum >= 1_000_000_000) {
    return `${sign * (absNum / 1_000_000_000).toFixed(2)}B`; // Billion
  } else if (absNum >= 1_000_000) {
    return `${sign * (absNum / 1_000_000).toFixed(2)}M`; // Million
  } else if (absNum >= 1_000) {
    return `${sign * (absNum / 1_000).toFixed(2)}k`; // Thousand
  } else {
    return `${sign * absNum.toFixed(2)}`; // Below Thousand
  }
}


export function convertUTCDateToLocalDate(date) {

  var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  //console.log(newDate);
  return newDate;
  //return newDate; 

  //var offset = new Date().getTimezoneOffset();
  // var newDate =  ;//new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  // var offset = date.getTimezoneOffset() / 60;
  // var hours = date.getHours();

  // newDate.setHours(hours - offset);

  // return newDate;  
}

export function getGatewayName(gatewayType) {
  switch (gatewayType) {
    case 1:
      return 'Stripe';
    case 2:
      return 'Paypal';
    case 3:
      return 'UPI';
    // Add more cases here for other payment gateways
    default:
      return 'N/A';
  }
};


export const fetchPosts = (page = 1, limit = 7) => {
  const sorted = [...blogPosts].sort((a, b) => b.id - a.id); // Descending order by ID
  const start = (page - 1) * limit;
  return Promise.resolve(sorted.slice(start, start + limit));
};

export const fetchPostBySlug = (slug) => {
  const post = blogPosts.find(p => p.slug === slug);
  return Promise.resolve(post);
};

export const fetchSimilarPosts = (tags = []) => {
  const matches = blogPosts.filter(p => p.tags.some(tag => tags.includes(tag)));
  return Promise.resolve(matches.sort((a, b) => b.id - a.id).slice(0, 3));
};
