import React, { useEffect, useState } from "react";
import Slider from "react-slick"; // Import Slick slider
import DotsCarousel from "./DotsCarousel";
import Axios from 'axios';
import ReactGA from "react-ga4";
import loader_img from '../assets/images/loader_img.gif';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ExploreDotCategory = ({ selectedCategoryProps, exploreCategoryRef }) => {

    const [dotsData, setDotsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect screen size
    const App_url = 'https://learnee.page.link/rniX'

    const categories = [
        { name: "Self-improvement", displayName: "Self Improvement" },
        { name: "Self-Help", displayName: "Self-Help" },
        { name: "Health and Wellness", displayName: "Health & Wellness" },
        { name: "Literature", displayName: "Literature" }, // Adjusted to "Literature"
        { name: "Cultural and Religious", displayName: "Cultural & Religious" },
        { name: "Finance & Investment", displayName: "Finance & Investment" }, // New category
        { name: "Food & Recipes", displayName: "Food & Recipes" },
        { name: "Entertainment", displayName: "Entertainment" },
        { name: "Self-help books", displayName: "Books" }, // New category
        { name: "Life Lessons", displayName: "Life Lessons" }, // New category
        { name: "Education", displayName: "Education" },
        { name: "Science and Technology", displayName: "Science & Technology" }, // New category
        { name: "Travel & Tourism", displayName: "Travel & Tourism" },
        { name: "Business Tools", displayName: "Business tools" }, // New category
        { name: "Personal Development", displayName: "Personal Development" }, // New category
        { name: "Lifestyle", displayName: "Lifestyle" },
        { name: "Mental Health", displayName: "Mental Health" }, // New category
        { name: "Productivity", displayName: "Productivity" },
        { name: "Health and Nutrition", displayName: "Health & Nutrition" },
        { name: "Relationships", displayName: "Relationships" }, // New category
        { name: "Philosophy", displayName: "Philosophy" }, // New category
        { name: "Fitness", displayName: "Fitness" }, // New category
        { name: "Motivation", displayName: "Motivation" }, // New category
        { name: "Wellness", displayName: "Wellness" } // New category
    ];



    const categoryColors = {
        "Self-improvement": "#AA7DCE",
        "Self-Help": "#AA7DCE",
        "Health and Wellness": "#B20D30",
        "Literature": "#083D77",
        "Cultural and Religious": "#F71735",
        "Finance & Investment": "#AA7DCE",
        "Food & Recipes": "#F95738",
        "Entertainment": "#B20D30",
        "Self-help books": "#AA7DCE",
        "Life Lessons": "#AA7DCE",
        "Education": "#083D77",
        "Science and Technology": "#083D77",
        "Travel & Tourism": "#4EA5D9",
        "Business Tools": "#404E4D",
        "Personal Development": "#AA7DCE",
        "Lifestyle": "#B20D30",
        "Mental Health": "#B20D30",
        "Productivity": "#AA7DCE",
        "Health and Nutrition": "#F95738",
        "Relationships": "#AA7DCE",
        "Philosophy": "#083D77",
        "Fitness": "#B20D30",
        "Motivation": "#F86624",
        "Wellness": "#B20D30"
    };


    const generateAskVaSiDotsWebUrl = process.env.REACT_APP_BACKENDURL + 'askVaSiPublicApi';

    useEffect(() => {
        if (selectedCategoryProps) {
            setSelectedCategory(selectedCategoryProps);
            fetchDots(selectedCategoryProps);
        }
    }, [selectedCategoryProps]);

    const defaultPayload = {
        pageSize: 10,
        sortOrder: "desc",
        categories: "",
        isRecommended: false,
        query: "",
        pageOffset: 0,
        request_type: 'dots_categories',
        isFeatured: true,
        isPopular: false
    };

    const fetchDots = async (category) => {
        setLoading(true);
        try {
            if (category) {
                delete defaultPayload.isFeatured;
            }

            const payload = { ...defaultPayload, categories: category || "" };
            const response = await Axios.post(
                generateAskVaSiDotsWebUrl,
                { data: payload },
                { headers: { "Content-Type": "application/json" } }
            );

            setDotsData(response.data.result?.dots || []);

        } catch (error) {
            console.error("Error fetching dots:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDots();
    }, []);

    const handleCategoryClick = (category) => {
        if (selectedCategory === category) return;
        setSelectedCategory(category);
        handleButtonClick(category, "Explore Category")
        fetchDots(category);
    };

    const handleButtonClick = (action, label) => {
        ReactGA.event({
            category: "Button",
            action: action,
            label: label
        });
    };

    // Slick Slider settings for 3 rows at once
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4, // Number of columns
        slidesToScroll: 4, // Scroll three columns at a time
        rows: 4, // Number of rows
        slidesPerRow: 1, // Each slide takes 1 row
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2,
                    rows: 3,
                    slidesPerRow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2,
                    rows: 3,
                    slidesPerRow: 1,
                }
            }
        ]
    };

    return (
        <div ref={exploreCategoryRef}>
            {/* Show normal buttons on desktop */}
            {!isMobile ? (
                <div className="category-buttons">
                    {categories.map((category, index) => (
                        <button
                            key={index}
                            onClick={() => handleCategoryClick(category.name)}
                            className={selectedCategory === category.name ? "active" : ""}
                            style={{
                                backgroundColor: selectedCategory === category.name ? categoryColors[category.name] : "transparent",
                                color: selectedCategory === category.name ? "#fff" : "#000",
                            }}
                        >
                            {category.displayName}
                        </button>
                    ))}
                </div>
            ) : (
                /* Show slider on mobile */
                <Slider {...sliderSettings} className="category-slider category-buttons">
                    {categories.map((category, index) => (
                        <div key={index} className="category-slide">
                            <button
                                onClick={() => handleCategoryClick(category.name)}
                                className={selectedCategory === category.name ? "active" : ""}
                                style={{
                                    backgroundColor: selectedCategory === category.name ? categoryColors[category.name] : "transparent",
                                    color: selectedCategory === category.name ? "#fff" : "#000",
                                }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = categoryColors[category.name]}
                                onMouseLeave={(e) => e.target.style.backgroundColor = selectedCategory === category.name ? categoryColors[category.name] : "transparent"}
                            >
                                {category.displayName}
                            </button>
                        </div>
                    ))}
                </Slider>
            )}
            {loading && (
                <div className="carousel-loader-container">
                    <img src={loader_img} alt="Loading..." className="carousel-loader" />
                </div>
            )}

            <div className="carousel-container">
                <DotsCarousel dotsData={dotsData} />
            </div>


        </div>
    );
}

export default ExploreDotCategory;
