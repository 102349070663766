import React from "react";

import testimonial_img_1 from '../assets/images/consumer_landing/testimonial_img_1.png';
import testimonial_img_2 from '../assets/images/consumer_landing/testimonial_img_2.png';
import testimonial_img_3 from '../assets/images/consumer_landing/testimonial_img_3.png';

const testimonials = [
  {
    name: "Girish Jain",
    role: "Learner",
    image: testimonial_img_1, // Replace with actual image path
    rating: 5,
    feedback:
      "I love how Dots give me focused knowledge without the fluff. I’ve bought a few on productivity and habit-building, and they’ve been super helpful. Plus, I can revisit them anytime I need a refresher.",
  },
  {
    name: "Richa Thappar",
    role: "Learner",
    image: testimonial_img_2, // Replace with actual image path
    rating: 5,
    feedback:
      "I used to spend hours reading self-help books and articles, but I rarely applied what I learned. VaSi changed that. The Dots I buy come with reminders, so I actually take action. I’ve already improved my morning routine and productivity!",
  },
  {
    name: "Aditi Jangra",
    role: "Learner",
    image: testimonial_img_3, // Replace with actual image path
    rating: 5,
    feedback:
      "At first, I thought VaSi was just another note-taking app, but it’s so much more! I use it to store my personal reminders, keep track of ideas, and even discover useful Dots from other experts. Plus, knowing that I can sell my own knowledge is a huge motivator.",
  },
];

const TestimonialsSection = () => {
  return (
    <section className="testimonials-section">
      <div className="container">
        <h2 className="testimonials-title testimonials-title-heading">
          Join Thousand’s Of People Learning And Growing With VaSi
        </h2>
        <div className="testimonials-container">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
              />
              <div className="testimonial-stars">
                {"★".repeat(testimonial.rating)}
              </div>
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-role">{testimonial.role}</p>
              <p className="testimonial-text">“{testimonial.feedback}”</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
