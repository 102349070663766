import React, { useState, useEffect } from 'react';
import Axios from "axios"
import { getAuth } from "firebase/auth";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
//import { useParams } from "react-router-dom"
import { DropdownButton, Dropdown, Button } from "react-bootstrap";

import InfiniteScroll from 'react-infinite-scroll-component';
import { adminMarketplaceListState, adminMarketplaceQuery, adminMarketplaceFilterKeyState, adminMarketplaceSearchKeyState, adminMarketplacePageOffesetState } from "../../recoil/AdminMarketplaceDotsRecoil"
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import MarketplaceList from "./MarketplaceList"
import ApiFetchingData from '../ApiFetchingData';
import LoadingDotsIcon from "../LoadingDotsIcon"
import ExportModal from "./ExportModal";  // Import the modal component

const auth = getAuth();

function MarketPlaceListsAdmin() {
    //const [search_query, setSearchQuery] = useState('');
    const [, setSelected] = useState('All');
    //const [marketplaceList, setmarketplaceList] = useState('')
    // const [marketplaceStatus, setMarketplaceStatus] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const pageSize = process.env.REACT_APP_PAGESIZE
    //const [pageOffset, setPageOffset] = useState(pageSize);
    const [noMore, setnoMore] = useState(true)
    const [, setIsFirstLoad] = useState(true)
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const marketplaceRequestUrl = process.env.REACT_APP_BACKENDURL + 'initiateMarketplaceSearchAdmin';

    const [marketplaceList, setmarketplaceList] = useRecoilState(adminMarketplaceListState)

    const [adminMarketplaceFilterKey, setAdminMarketplaceFilterKey] = useRecoilState(adminMarketplaceFilterKeyState)

    const [search_query, setSearchQuery] = useRecoilState(adminMarketplaceSearchKeyState)

    const [pageOffset, setPageOffset] = useRecoilState(adminMarketplacePageOffesetState)


    const adminMarketplaceIniItems = useRecoilValueLoadable(adminMarketplaceQuery);

    const list = [
        { key: "0", value: "All Active" },
        { key: "1", value: "Popular" },
        { key: "2", value: "Featured" },
        { key: "3", value: "Promoted" },
        { key: "4", value: "Priced" },
        { key: "5", value: "Free" },
        // { key: "55", value: "Payout Completed" }

    ];

    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    //console.log("adminMarketplaceFilterKey===", adminMarketplaceFilterKey);



    useEffect(() => {

        const marketplaceLists = () => {
            //console.log(adminMarketplaceIniItems.contents.result);
            switch (adminMarketplaceIniItems.state) {
                case 'hasValue':
                    if (typeof adminMarketplaceIniItems.contents.result !== 'undefined') {
                        setmarketplaceList(adminMarketplaceIniItems.contents.result.dots);
                    }
                    setAdminMarketplaceFilterKey(adminMarketplaceFilterKey)
                    setIsLoading(false)
                    if (typeof adminMarketplaceIniItems.contents.result !== 'undefined' && adminMarketplaceIniItems.contents.result.dots.length < 20) {
                        setnoMore(false);
                    } else {
                        setnoMore(true);
                    }
                    return
                default:
                case 'loading':
                    setIsLoading(true)
                    return
                case 'hasError':
                    setIsLoading(false);
                    return
            }
        };

        if (marketplaceList.length === 0) {
            marketplaceLists()
        } else {
            setmarketplaceList(marketplaceList);
            setIsLoading(false);
        }
    }, [adminMarketplaceIniItems, setAdminMarketplaceFilterKey, adminMarketplaceFilterKey, setmarketplaceList, marketplaceList])




    const fetchFilteredData = async (marketplaceStatus) => {
        //setCheckingInProgress(true)  
        //setPageOffset(pageSize);
        setIsLoading(true);

        console.log('helloo')
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth?.currentUser?.accessToken
        }
        const filterRequest = Axios.CancelToken.source();
        setIsFirstLoad(false)

        try {
            const response = await Axios.post(marketplaceRequestUrl, {
                data: {
                    "query": "",
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "sortBy": "createdAt",
                    ...marketplaceStatus
                }
            },
                { cancelToken: filterRequest.token });
            const json = await response.data.result;

            if (json.status === 200) {
                setPageOffset(pageSize)
                setmarketplaceList(json.dots);
            }

            if (json.status === 400) {
                setmarketplaceList('');
            }
            setIsLoading(false);
            //console.log(setmarketplaceList);
        } catch (er) {
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }

    }

    const fetchMoreData = async () => {
        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth?.currentUser?.accessToken
        }
        setIsFirstLoad(false)
        var requestURL = marketplaceRequestUrl;

        var selectedFilter = adminMarketplaceFilterKey;//localStorage.getItem('selectedFilter') !== null ? parseInt(localStorage.getItem

        // Map key to appropriate filter parameter
        let filter = getFilterParams(selectedFilter)


        // 
        // ('selectedFilter')) : 0
        //console.log("selectedFilter===", adminMarketplaceFilterKey);
        var requestData = {
            "query": "",
            "pageOffset": pageOffset,
            "pageSize": pageSize,
            "sortBy": "createdAt",
            ...filter
        }

        if (search_query !== '') {
            requestURL = marketplaceRequestUrl;//Request URL changed in case of search
            requestData = {
                "query": search_query,
                "pageOffset": pageOffset,
                "pageSize": pageSize,
                "sortBy": "createdAt",
                ...filter
            }
        }

        //console.log(requestData);

        try {
            const response = await Axios.post(requestURL, {
                data: requestData,
            },
                { cancelToken: ourRequest.token }
            );

            const json = await response.data.result;
            setIsFirstLoad(true)
            return json.dots;
        } catch (e) {
            setIsFirstLoad(true)
            console.log("There was a problem or the request was cancelled." + e)
            //setIsLoading(false);
        }
    }

    const fetchData = async () => {

        const marketplaceLoadMore = await fetchMoreData();
        //console.log("marketplaceLoadMore===");
        if (marketplaceLoadMore.length > 0) {
            setmarketplaceList((marketplaceList) => [...marketplaceList, ...marketplaceLoadMore]);
            if (marketplaceLoadMore.length === 0 || marketplaceLoadMore.length < Number(pageSize)) {
                setnoMore(false);
            }
            setPageOffset(marketplaceList.length + Number(pageSize))
        } else {
            setnoMore(false);
        }
    }

    //console.log("noMore===",noMore)
    const fetchSearchedData = async (marketplaceStatus) => {
        //setCheckingInProgress(true)    
        setIsLoading(true);
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + auth?.currentUser?.accessToken
        }
        const filterRequest = Axios.CancelToken.source();
        setIsFirstLoad(false)

        let filter = getFilterParams(marketplaceStatus)


        try {
            const response = await Axios.post(marketplaceRequestUrl, {
                data: {

                    "query": search_query,
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "sortBy": "createdAt",
                    ...filter
                }
            },
                { cancelToken: filterRequest.token });
            const json = await response.data.result;
            if (json.status === 200) {
                setmarketplaceList(json.dots);
            }

            if (json.status === 404) {
                setmarketplaceList('');
            }
            setIsLoading(false);
            //console.log(setmarketplaceList);
        } catch (er) {
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }

    }


    // Common method to map selectedFilter to the corresponding filter parameters
    function getFilterParams(selectedFilter) {
        const filter = {
            isPopular: false,
            isFeatured: false,
            isPromoted: false,
        };

        if (selectedFilter === 1) {
            filter.isPopular = true;
        } else if (selectedFilter === 2) {
            filter.isFeatured = true;
        } else if (selectedFilter === 3) {
            filter.isPromoted = true;
        } else if (selectedFilter === 4) {
            filter.isPrice = true;
        } else if (selectedFilter === 5) {
            filter.isPrice = false;
        }

        return filter;
    }


    function handleSelect(key, value) {
        setPageOffset('0');
        setnoMore(true);

        setAdminMarketplaceFilterKey(parseInt(key))

        // Map key to appropriate filter parameter
        let filter = {
            isPopular: false,
            isFeatured: false,
            isPromoted: false                     
        };


        if (key === '1') {
            filter.isPopular = true;
        } else if (key === '2') {
            filter.isFeatured = true;
        } else if (key === '3') {
            filter.isPromoted = true;
        } else if (key === '4') {
            filter.isPrice = true;
        } else if (key === '5') {
            filter.isPrice = false;
        }

        fetchFilteredData(filter)

        setSearchQuery('')
        setSelected(value);

    };

    function handleSearchInput(e) {
        e.preventDefault();
        const value = e.target.value
        if (value.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
        //console.log(value);
        setSearchQuery(value)
    }

    function submitSearchData(e) {
        e.preventDefault();
        setPageOffset(0);
        if (search_query !== '') {
            fetchSearchedData(adminMarketplaceFilterKey);
        } else {
            fetchFilteredData(adminMarketplaceFilterKey);
            setnoMore(true);
        }
        //console.log(search_q);
    }

    const handleRemoveItemFromList = (itemToRemove) => {
        //console.log("itemToRemove11==", itemToRemove)
        // Filter out the removed item and update the list
        const updatedItems = marketplaceList.filter((item) => item.mktPlaceId !== itemToRemove);
        setmarketplaceList(updatedItems);
    };

    // Handle the modal export completion
    const handleExportComplete = () => {
        setShowModal(false); // Close the modal after export is done
    };

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light userlist">
            <DashboardHeader></DashboardHeader>
            <div className='container-fluid marketplace-table-header'>
                <div className="dropdown header-nav d-md-flex" style={{ float: "left" }}>
                    <DropdownButton
                        id="dropdown-basic-button"
                        variant="info"
                        className="floatRight"
                        title={
                            list.find(item => item.key === adminMarketplaceFilterKey.toString())?.value || 'All'
                        }
                    >
                        {list.map((item, index) => {
                            return (
                                <Dropdown.Item onClick={() => handleSelect(item.key, item.value)} key={index} eventKey={item.value}>
                                    {item.value}
                                </Dropdown.Item>
                            );
                        })}
                    </DropdownButton>


                    {/* Export Button */}
                    <div className="ms-3 float-right">
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            Export CSV
                        </Button>
                    </div>

                </div>

                {/* Use the ExportModal component */}
                <ExportModal
                    show={showModal}
                    onHide={() => setShowModal(false)}  // Close modal when clicked on close button
                    onExport={handleExportComplete}  // Notify when export is complete
                    marketplaceList={marketplaceList}
                />

                <div className='ms-3 float-right'>
                    <form className='me-xxl-3 admin-search'>
                        <div className="input-group">
                            <input className="form-control py-2 border-right-0 border searchBox" placeholder="Search by title, category..." onChange={handleSearchInput} type="text" id="srch-term" value={search_query} />
                        </div>
                        <span className="margin-left-10">
                            <button className={"btn btn-primary border-left-0 " + (isButtonDisabled ? 'disabled' : '')} type="submit" onClick={submitSearchData}>
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive dotslisting">

                    {isLoading &&
                        <LoadingDotsIcon />
                    }



                    {typeof marketplaceList !== 'undefined' &&
                        <InfiniteScroll
                            dataLength={marketplaceList.length}
                            next={fetchData}
                            hasMore={noMore}
                            loader={marketplaceList.length > 20 && <ApiFetchingData />}
                        >

                            {marketplaceList &&
                                <MarketplaceList
                                    data={marketplaceList} isArchived={false} onRemoveItemDots={handleRemoveItemFromList} />
                            }

                            {!marketplaceList && !isLoading &&
                                <div className="text-center margin-top-10">No Result!</div>
                            }

                        </InfiniteScroll>
                    }



                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>
    );
}

export default MarketPlaceListsAdmin;