import React, { useState } from 'react';
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import DatePicker from 'react-datepicker';
import { getAuth } from "firebase/auth";
import 'react-datepicker/dist/react-datepicker.css';
import Axios from "axios"

const AdminSilentNotificationSender = () => {
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [placeholderColor, setPlaceholderColor] = useState('');
    const [categoryImageURL, setCategoryImageURL] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [foregroundColor, setForegroundColor] = useState('#FFFFFF');
    const [mktDotId, setMktDotId] = useState('');
    const [categoryTitle, setCategoryTitle] = useState('');
    const [categoryDesc, setCategoryDesc] = useState('');
    const [category, setCategory] = useState('34');
    const [platform, setPlatform] = useState('');
    const [topic, setTopic] = useState('');
    const [error, setError] = useState('');
    const [userId, setUserId] = useState('');
    const [scheduledDate, setScheduledDate] = useState(null); // State for scheduled date/time
    const [isSendNowButtonDisabled, setIsSendNowButtonDisabled] = useState(false);
    const [isScheduleButtonDisabled, setIsScheduleButtonDisabled] = useState(false);

    const [showBackgroundList, setShowBackgroundList] = useState(false);
    const [showPlaceholderList, setShowPlaceholderList] = useState(false);


    const auth = getAuth();
    localStorage.setItem('userAuth', auth.currentUser.accessToken);


    const categoryColors = {
        "#AA7DCE": ["Life Lessons", "Life skills", "Personal Development", "Productivity", "Relationships", "Self-help", "Self-help Books", "Self-improvement", "Spiritual Growth", "Spirituality"],
        "#B20D30": ["Fitness", "Health and Wellness", "Lifestyle", "Mental Health", "Sports", "Wellness"],
        "#F86624": ["Empowerment", "Gender Equality", "Inspiration", "Inspiration talks", "LGBTQ+", "Motivation", "Privacy Risks"],
        "#104F55": ["Animal welfare", "Environmental Conservation"],
        "#F4ACB7": ["Beauty & Fashion", "Cosmetics"],
        "#3E6990": ["Books", "Writing Prompts", "Writing Tips"],
        "#404E4D": ["Business Tools", "Event management"],
        "#F71735": ["Cultural and Religious"],
        "#17BEBB": ["DIY"],
        "#EAD7D1": ["Design"],
        "#9F956C": ["Documentaries"],
        "#083D77": ["Education", "Excel Functions", "Historical Information", "Literature", "News", "Philosophy", "Political Science", "Psychology", "Science and Technology", "Technology"],
        "#119822": ["Finance & Investment"],
        "#F95738": ["Food And Recipes", "Health and Nutrition"],
        "#4EA5D9": ["Travel & Tourism"],
        "#B96D40": ["Job search", "Others", "Parenting", "Safety Tips", "YouTube Channel Growth Strategies"]
    };

    const placeholderColors = {
        "#D8B2F7": "Life Lessons",
        "#882036": "Fitness",
        "#FF9666": "Empowerment",
        "#FEC2CB": "Beauty & Fashion",
        "#274763": "Animal welfare",
        "#7F8D8C": "Books",
        "#FFABB6": "Business Tools",
        "#2D7876": "Cultural and Religious",
        "#B6A7A2": "DIY",
        "#7F7756": "Design",
        "#6791C0": "Documentaries",
        "#5EBC69": "Education",
        "#C6513B": "Finance & Investment",
        "#91D3FB": "Food And Recipes",
        "#DDA27F": "Travel & Tourism"
    };



    const handleSubmit = (event, scheduled) => {
        event.preventDefault();

        scheduled ? setIsScheduleButtonDisabled(true) : setIsSendNowButtonDisabled(true);

        // Basic validation
        if (!title || !desc || !category || !categoryName || !categoryTitle || !categoryDesc || !categoryImageURL) {

            scheduled ? setIsScheduleButtonDisabled(false) : setIsSendNowButtonDisabled(false);

            setError('Please fill in all fields.');
            // Auto-hide error after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000);
            return;
        }

        if (!topic && !userId) {
            scheduled ? setIsScheduleButtonDisabled(false) : setIsSendNowButtonDisabled(false);

            setError('Please select the topic.');
            // Auto-hide error after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000);
            return;
        }

        // Validation successful - proceed to send form values to server
        setError('');

        const date = new Date(scheduledDate);

        // Get the timestamp in milliseconds
        const scheduledTimestamp = date.getTime();

        // List of available topics for dropdown
        const topics_ios = [
            { fullName: 'India(iOS)', shortName: 'VaSi-IN' },
            { fullName: 'United States(iOS)', shortName: 'VaSi-US' },
            { fullName: 'Canada(iOS)', shortName: 'VaSi-CAN' },
            { fullName: 'All Users(iOS)', shortName: 'VaSi' }
            // Add more countries as needed...
        ];

        console.log("topic===", topic)
        const searchTopic = topic
        const exists = topics_ios.some(topic => topic.shortName === searchTopic);

        console.log("exists==", exists)
        let topicPayload;
        if (exists || platform === '1') {
            // Create the JSON object with dynamic values
            topicPayload = {
                payload: {
                    data: {
                        title,
                        desc,
                        categoryTitle,
                        categoryDesc,
                        category,
                        mktDotId,
                        placeholderColor,
                        categoryImageURL,
                        backgroundColor,
                        foregroundColor,
                        categoryName
                    },
                    notification: {
                        title: title,
                        body: desc
                    }
                }
            };

        } else {
            topicPayload = {
                payload: {
                    data: {
                        title,
                        desc,
                        category,
                        ownerName: "VaSi",
                        mktDotId,
                        categoryTitle,
                        categoryDesc,
                        placeholderColor,
                        categoryImageURL,
                        backgroundColor,
                        foregroundColor,
                        categoryName
                    }
                }
            };

        }

        if (!mktDotId) {
            delete topicPayload.payload.data.mktDotId;
        }

        console.log("topicPayload===", topicPayload);


        const topicData = userId ? userId : topic

        // Simulating sending form values to a server 
        const formData = {
            payload: topicPayload.payload,
            topic: topicData,
            triggerTime: scheduledTimestamp,
            isPending: scheduled,
            isAdmin: true
            // isSilent: true
        };
        console.log('Sending form data to server:', formData);
        // Your logic to send formData to the server using fetch, axios, etc.

        try {
            let URL
            if (scheduled) {
                URL = process.env.REACT_APP_BACKENDURL + 'sendPushnotification'//'https://us-central1-learnee-qa-3b75b.cloudfunctions.net/sendPushnotification';
            } else {
                URL = process.env.REACT_APP_BACKENDURL + 'sendFCMNotificationForSharedDot';
            }

            const ourRequest = Axios.CancelToken.source()
           // console.log( auth?.currentUser?.accessTokens);
            Axios.defaults.headers = {
                Authorization: 'Bearer ' + auth?.currentUser?.accessToken
            }

            const response = Axios.post(URL, {
                data: formData
            },
                { cancelToken: ourRequest.token });
            const json = response.data;
            if (json.result.status === 200) {
                console.log("Push notification sent out!")
            } else {
                if (json.result.status === 401) {
                    alert('Session Expired!')
                }
                console.log("Something went wrong!")
            }

            setTimeout(() => {
                scheduled ? setIsScheduleButtonDisabled(false) : setIsSendNowButtonDisabled(false);
            }, 2000);

        } catch (e) {
            console.log("There was a problem or the request was cancelled.")
            setTimeout(() => {
                scheduled ? setIsScheduleButtonDisabled(false) : setIsSendNowButtonDisabled(false);
            }, 2000);
        }

    };

    // List of available topics for dropdown
    const topics = [
        { fullName: 'India(iOS)', shortName: 'VaSi-IN' },
        { fullName: 'United States(iOS)', shortName: 'VaSi-US' },
        { fullName: 'Canada(iOS)', shortName: 'VaSi-CAN' },
        { fullName: 'All Users(iOS)', shortName: 'VaSi' },
        { fullName: 'India(Android)', shortName: 'VaSi-A-IN' },
        { fullName: 'United States(Android)', shortName: 'VaSi-A-US' },
        { fullName: 'Canada(Android)', shortName: 'VaSi-A-CAN' },
        { fullName: 'All Users(Android)', shortName: 'VaSi-A' }
        // Add more countries as needed...
    ];

    const platforms = [
        { name: 'iOS', value: '1' },
        { name: 'Android', value: '2' }
    ]

    const handleBackgroundColorSelect = (color) => {
        setBackgroundColor(color);
        setShowBackgroundList(false); // Close the list when a color is selected
    };

    const handlePlaceholderColorSelect = (color) => {
        setPlaceholderColor(color);
        setShowPlaceholderList(false); // Close the list when a color is selected
    };

    const datePickerRef = React.createRef(); // Create a reference for the DatePicker component

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light userlist">
            <DashboardHeader></DashboardHeader>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-5"> {/* Added mb-4 for bottom margin */}
                        <h2 className="text-center mb-4">Send Promotional Push Notification</h2>
                        <form onSubmit={handleSubmit}>

                            <div className="mb-3 row g-2">
                                <div className="col">
                                    <label className="form-label">Notification Title:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Notification Description:</label>
                                <textarea
                                    className="form-control"
                                    rows="3" // Adjust rows as needed
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                />
                            </div>


                            <hr />

                            <div className="mb-3 row g-2">
                                <div className="col">
                                    <label className="form-label">Title:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={categoryTitle}
                                        onChange={(e) => setCategoryTitle(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 row g-2">
                                <div className="col">
                                    <label className="form-label">Category Name:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Description:</label>
                                <textarea
                                    className="form-control"
                                    rows="3" // Adjust rows as needed
                                    value={categoryDesc}
                                    onChange={(e) => setCategoryDesc(e.target.value)}
                                />
                            </div>

                            {/* Background Color Input */}
                            <div className="mb-3 row g-2 position-relative admin-color-picker">
                                <div className="col">
                                    <label className="form-label">Background Color:</label>
                                    <div className="d-flex">
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={backgroundColor}
                                            onChange={(e) => setBackgroundColor(e.target.value)}
                                            onClick={() => setShowBackgroundList(!showBackgroundList)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary ms-2"
                                            onClick={() => setShowBackgroundList(!showBackgroundList)}
                                        >
                                            ✏️
                                        </button>
                                    </div>
                                    {showBackgroundList && (
                                        <ul className="list-group mt-2">
                                            {Object.keys(categoryColors).map((color) => (
                                                <li
                                                    key={color}
                                                    className="list-group-item"
                                                    style={{ backgroundColor: color, cursor: 'pointer' }}                                                   
                                                    onClick={() => handleBackgroundColorSelect(color)}
                                                >
                                                    {categoryColors[color].join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                {/* Placeholder Color Input */}
                                <div className="col">
                                    <label className="form-label">Placeholder Color:</label>
                                    <div className="d-flex">
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={placeholderColor}
                                            onChange={(e) => setPlaceholderColor(e.target.value)}
                                            onClick={() => setShowPlaceholderList(!showPlaceholderList)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary ms-2"
                                            onClick={() => setShowPlaceholderList(!showPlaceholderList)}
                                        >
                                            ✏️
                                        </button>
                                    </div>
                                    {showPlaceholderList && (
                                        <ul className="list-group mt-2">
                                            {Object.keys(placeholderColors).map((color) => (
                                                <li
                                                    key={color}
                                                    className="list-group-item"
                                                    style={{ backgroundColor: color, cursor: 'pointer' }}
                                                    onClick={() => handlePlaceholderColorSelect(color)} 
                                                >
                                                    {placeholderColors[color]}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                <div className="col">
                                    <label className="form-label">Foreground Color:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={foregroundColor}
                                        onChange={(e) => setForegroundColor(e.target.value)}
                                    />
                                </div>
                            </div>


                            <div className="mb-3 row g-2">
                                <div className="col">
                                    <label className="form-label">Category Image URL:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={categoryImageURL}
                                        onChange={(e) => setCategoryImageURL(e.target.value)}
                                    />
                                </div>
                            </div>


                            <div className="mb-3 row g-2">
                                {!userId &&
                                    <div className="col">
                                        <label className="form-label">Topic:</label>
                                        <select
                                            className="form-select"
                                            value={topic}
                                            onChange={(e) => setTopic(e.target.value)}
                                        >
                                            <option value="">Select topic</option>
                                            {topics.map((country, index) => (
                                                <React.Fragment key={index}>
                                                    <option value={country.shortName}>
                                                        {country.fullName}
                                                    </option>
                                                    {(index + 1) % 4 === 0 && index !== topics.length - 1 && (
                                                        <option value="" disabled>---</option>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </select>
                                    </div>
                                }

                                <div className="col">
                                    <label className="form-label">Category:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 row g-2">
                                <div className="col">
                                    <label className="form-label">FUID:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) => setUserId(e.target.value)}
                                        value={userId}
                                    />
                                </div>
                                {userId &&
                                    <div className="col">
                                        <label className="form-label">Platform:</label>
                                        <div>
                                            {platforms.map((option, index) => (
                                                <label key={index} className="form-check-label me-3">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input me-1"
                                                        value={option.value}
                                                        checked={platform === option.value}
                                                        onChange={(e) => setPlatform(e.target.value)}
                                                    />
                                                    {option.name}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="mb-3 row g-2">
                                <div className="col">
                                    <label className="form-label">Schedule Date/Time:</label>
                                    <br />
                                    <DatePicker
                                        className="form-control"
                                        selected={scheduledDate}
                                        onChange={(date) => setScheduledDate(date)}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        placeholderText="Select Date/Time"

                                        ref={datePickerRef} // Assign the reference to the DatePicker component
                                    />
                                </div>
                                <div className="col">
                                    <label className="form-label">Marketplace Id:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={mktDotId}
                                        onChange={(e) => setMktDotId(e.target.value)}
                                    />
                                </div>
                            </div>

                            {error && <div className="text-danger mb-3">{error}</div>}
                            <div className="mb-3">
                                <button
                                    className="btn btn-primary me-2"
                                    disabled={scheduledDate || isSendNowButtonDisabled}
                                    onClick={(e) => handleSubmit(e, false)} // Send Now button
                                >
                                    {isSendNowButtonDisabled ? 'Sending...' : 'Send Now'}
                                </button>
                                <button
                                    className="btn btn-primary"
                                    disabled={!scheduledDate || isScheduleButtonDisabled}
                                    onClick={(e) => handleSubmit(e, true)} // Schedule button
                                >
                                    {isScheduleButtonDisabled ? 'Scheduling...' : 'Schedule'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>
    );
};

export default AdminSilentNotificationSender;
