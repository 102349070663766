import Axios from "axios"
import { atom, selector } from "recoil";
export const adminMarketplaceListState = atom({
    key: "adminMarketplaceListState",
    default: [],
});


export const adminMarketplaceQuery = selector({
    key: 'adminMarketplaceQuery',
    get: async ({ get }) => {
        try {
            const searchKey = get(adminMarketplaceSearchKeyState)
            const filterKey = get(adminMarketplaceFilterKeyState)
            console.log("filterKey===", filterKey);

            // Map key to the appropriate filter parameter
            let filter = {
                isPopular: false,
                isFeatured: false,
                isPromoted: false,
            };

            if (filterKey === 1) {
                filter.isPopular = true;
            } else if (filterKey === 2) {
                filter.isFeatured = true;
            } else if (filterKey === 3) {
                filter.isPromoted = true;
            } else if (filterKey === 4) {
                filter.isPrice = true;
            } else if (filterKey === 5) {
                filter.isPrice = false;
            }

            console.log(filter);

            const ourRequest = Axios.CancelToken.source()
            const pageSize = process.env.REACT_APP_PAGESIZE
            const URL = process.env.REACT_APP_BACKENDURL + 'initiateMarketplaceSearchAdmin';
            Axios.defaults.headers = {
                Authorization: 'Bearer ' + localStorage.getItem('userAuth')
            }
            const response = await Axios.post(URL, {
                data: {
                    "query": searchKey,
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "sortBy": "createdAt",
                    ...filter
                },
            },
                { cancelToken: ourRequest.token });
            return response.data;
        } catch (e) {
            return e
        }

    }
});


export const adminMarketplaceElementState = atom({
    key: "adminMarketplaceElementState",
    default: '',
});

export const adminMarketplaceSearchKeyState = atom({
    key: "adminMarketplaceSearchKeyState",
    default: '',
});

export const adminMarketplaceFilterKeyState = atom({
    key: "adminMarketplaceFilterKeyState",
    default: '',
});

export const adminMarketplaceButtonState = atom({
    key: "adminMarketplaceButtonState",
    default: '',
});

export const adminMarketplacePageOffesetState = atom({
    key: "adminMarketplacePageOffesetState",
    default: '20',
});