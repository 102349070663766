import React, { useEffect } from 'react';

import FAQ from "../FAQ"
import NavBar from '../NavBar';
import LandingPageFooter from '../LandingPageFooter';
import LandingPageFooterCosumer from '../LandingPageFooterCosumer';
function FAQPage() {
    useEffect(() => {       
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="askvasi-home">
            <NavBar />
            <div className="container outer-page">
                {/* <header className="masthead_inner">
                    <div className="container px-5 text-center">
                        <h1>FAQ</h1>
                    </div>
                </header> */}
                <aside className="inner_pages">
                    <div className="container px-5">
                        <div className="row gx-5">
                            <FAQ />

                        </div>
                    </div>
                </aside>
            </div>
            <LandingPageFooterCosumer />
        </div>
    );
}

export default FAQPage;
