import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const getUsersApiUrl = process.env.REACT_APP_BACKENDURL + 'getUserProfileInfo';

function ExportCSVModal({ show, onHide }) {
    const [csvCount, setCsvCount] = useState(200);
    const [csvOffset, setCsvOffset] = useState(0);
    const [exportType, setExportType] = useState('all'); // all | inactive
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);

    const handleExport = async () => {
        if (!csvCount || isNaN(csvCount)) {
            alert('Please enter a valid user count.');
            return;
        }

        const maxAllowed = 1000;
        if (csvCount > maxAllowed) {
            alert(`You can only export up to ${maxAllowed} users at a time.`);
            return;
        }

        // ✅ Validate date range if inactive selected
        if (exportType === 'inactive') {
            if (!startDate || !endDate) {
                alert('Please select both start and end dates.');
                return;
            }
            if (new Date(startDate) > new Date(endDate)) {
                alert('Start date cannot be later than end date.');
                return;
            }
        }

        setLoading(true);

        try {
            const request_type =
                exportType === 'inactive' ? "exportDropOffUsersCsv" : "exportUsersCsv";

            const payload = {
                request_type,
                count: parseInt(csvCount),
                offset: parseInt(csvOffset) || 0,
            };

            if (exportType === 'inactive') {
                payload.startDate = startDate;
                payload.endDate = endDate;
            }

            const response = await axios.post(getUsersApiUrl, {
                data: payload,
            });

            const { result } = response.data;

            if (result?.status === 200 && result.fileContent) {
                const blob = new Blob(
                    [new Uint8Array(atob(result.fileContent).split("").map(c => c.charCodeAt(0)))],
                    { type: 'text/csv' }
                );

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = result.fileName;
                link.click();
                URL.revokeObjectURL(link.href);

                onHide();
            } else {
                setTimeout(() => {
                    alert('Export failed or returned no data.');
                }, 500);
            }
        } catch (err) {
            console.error("CSV export error:", err);
            alert("Something went wrong while exporting.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Export Users as CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Select export type</Form.Label>
                        <Form.Select
                            value={exportType}
                            onChange={(e) => setExportType(e.target.value)}
                        >
                            <option value="all">All Users</option>
                            <option value="inactive">Inactive Users (by date range)</option>
                        </Form.Select>
                    </Form.Group>

                    {exportType === 'inactive' && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </>
                    )}

                    <Form.Group>
                        <Form.Label>Total users to export</Form.Label>
                        <Form.Control
                            type="number"
                            value={csvCount}
                            onChange={(e) => setCsvCount(e.target.value)}
                            min="1"
                        />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Offset (for batching)</Form.Label>
                        <Form.Control
                            type="number"
                            value={csvOffset}
                            onChange={(e) => setCsvOffset(e.target.value)}
                            min="0"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} disabled={loading}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleExport} disabled={loading}>
                    {loading ? "Exporting..." : "Export CSV"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ExportCSVModal;
