import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageMeta = () => {
    const location = useLocation();



    useEffect(() => {

        const rawPath = location.pathname;
        // Normalize path: remove trailing slash unless it's the root `/`
        const normalizedPath = rawPath !== '/' ? rawPath.replace(/\/+$/, '') : '/';

        // Page-specific titles
        const titles = {
            "/": "VaSi – Buy Expert Knowledge & Insights",
            "/earn": "VaSi – Earn Money by Sharing Your Knowledge",
            "/about": "About Us - Extend Your Mind",
            "/contact-us": "Contact Us - VaSi",
        };

        // Page-specific descriptions
        const descriptions = {
            "/": "Find expert advice, life lessons, and actionable tips on VaSi. Get valuable knowledge from real people. Start exploring today!",
            "/earn": "Turn your expertise into income on VaSi. Share insights and start earning today!",
            "/about": "Learn more about VaSi’s mission to empower minds and redefine the way people learn and earn.",
            "/contact-us": "Need help? Contact the VaSi team with any questions, feedback, or partnership opportunities.",
        };

        const path = normalizedPath; // Use the normalized path
        document.title = titles[path] || "VaSi - Extend Your Mind";

        // Update or create meta description tag
        const metaDesc = document.querySelector("meta[name='description']");
        const descContent = descriptions[path] || "VaSi is a platform to explore, share, and monetize expert knowledge.";
        if (metaDesc) {
            metaDesc.setAttribute("content", descContent);
        } else {
            const meta = document.createElement('meta');
            meta.name = "description";
            meta.content = descContent;
            document.head.appendChild(meta);
        }
    }, [location]);
};

export default usePageMeta;
