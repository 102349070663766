import React, { useState } from "react";

const faqs = [
  {
    question: "What can VaSi do for me?",
    answer:
      "VaSi allows you to <strong>monetize your knowledge and experiences</strong> by creating and selling <strong>Dots</strong>——bite-sized, actionable content that provides value to others. Whether you have expertise in a specific field, life lessons to share, or practical tips, VaSi helps you turn your insights into earnings.",
  },
  {
    question: "How can I use VaSi?",
    answer: `Using VaSi is simple:<br />
      1. <strong>Sign up</strong> and set up your profile.<br />
      2. <strong>Create a Dot</strong>——share your knowledge in a structured, easy-to-consume format.<br />
      3. <strong>Publish your Dot</strong> as free or paid content.<br />
      4. <strong>Earn</strong> when users purchase your paid Dots.<br />
      5. <strong>Withdraw your earnings</strong> through Stripe or PayPal.`,
  },
  {
    question: "What kind of content should I post on VaSi?",
    answer: `VaSi is designed for <strong>valuable, insightful, and actionable content</strong>. You can post:<br />
      ✅ Life lessons and personal experiences<br />
      ✅ Productivity and self-improvement tips<br />
      ✅ Career and business advice<br />
      ✅ Wellness, fitness, and mental health guidance<br />
      ✅ Learning hacks and practical skills<br />
      ✅ Any knowledge that others can benefit from!`,
  },
  {
    question: "What is a Dot?",
    answer:
      "A <strong>Dot</strong> is a compact, structured piece of content that delivers insights, tips, or advice in a meaningful way. Think of it as a <strong>digital nugget of wisdom<strong>——short, engaging, and actionable!",
  },
  {
    question: "How can a person earn from VaSi?",
    answer:
      "You earn by selling <strong>priced Dots</strong> on the VaSi Marketplace. Each time a user purchases one of your Dots, you receive a share of the earnings after platform and payment processing fees. The higher the quality and relevance of your content, the more users will engage, leading to better earnings and chances of getting featured.",
  },
  {
    question: "How much can I earn from VaSi?",
    answer:
      "For a Dot priced at <strong>$0.99 USD</strong>, after platform fees (including app store commissions and transaction fees),  your earnings per sale typically range around <strong>$0.44 - $0.52 per Dot</strong>. The more engaging and valuable your content, the more you can earn!",
  },
  {
    question: "How does VaSi send payouts?",
    answer:
      "Payouts are processed via <strong>Stripe or PayPal</strong> in USD. Your earnings are held for <strong>30 days</strong> (aligned with app store payment cycles), after which you can <strong>withdraw anytime</strong> once your balance reaches <strong>$0.99 or more</strong>. You are responsible for any applicable currency conversion or transaction fees.",
  },
];

const FAQCreators = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-section faq-section-creators consumer-page-landing">
      <div className="container">
        <h2 className="faq-title">Do you have any questions?</h2>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{faq.question}</span>
                <span className="faq-icon">{openIndex === index ? "−" : "+"}</span>
              </div>
              {openIndex === index && (
                <p
                  className="faq-answer"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQCreators;
