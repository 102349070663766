import React, { useState } from "react";

const faqs = [
  {
    question: "What is VaSi, and how does it help me?",
    answer:
      "VaSi is a marketplace where you can discover <strong>insightful, bite-sized</strong> knowledge called <strong>Dots</strong>—expert tips, life lessons, productivity hacks, and much more. Whether you want to learn new skills, improve your daily habits, or get inspiration, VaSi connects you with <strong>valuable, actionable content</strong> created by experts and experienced individuals.",
  },
  {
    question: "How can I use VaSi?",
    answer: `
      <strong>Using VaSi is simple:</strong><br>
      • <strong>Browse Categories</strong> – Explore different topics like Productivity, Health, Finance, and more.<br>
      • <strong>Search for Dots</strong> – Find specific content by Dot name, genre, or creator.<br>
      • <strong>Use ExploreAI</strong> – Ask a question, and VaSi will intelligently match you with the most relevant Dots.<br>
      • <strong>Purchase & Learn</strong> – Buy Dots, apply the insights, and track your personal growth!`,
  },
  {
    question: "What kind of content can I find on VaSi?",
    answer: `
      VaSi offers a wide range of <strong>practical and insightful</strong> content, including:<br>
      • <strong>Life lessons & personal growth tips</strong><br>
      • <strong>Career advice & professional skills</strong><br>
      • <strong>Health & wellness guides</strong><br>
      • <strong>Productivity & time management hacks</strong><br>
      • <strong>Financial tips & investing knowledge</strong><br>
      • <strong>Creativity & mindset coaching</strong><br>
      • And much more!`,
  },
  {
    question: "What is a Dot?",
    answer:
      "A <strong>Dot</strong> is a <strong>bite-sized knowledge capsule</strong>——a short but highly valuable piece of content containing tips, advice, or insights that you can <strong>immediately apply</strong> in real life.",
  },
  {
    question: "How do I discover the best Dots for me?",
    answer: `
      You can:<br>
      • <strong>Browse by Categories</strong> – Scroll through topic-based sections to explore different Dots.<br>
      • <strong>Search for Specific Dots</strong> – Find Dots by name, genre, or creator.<br>
      • <strong>Use ExploreAI for Smart Recommendations</strong> – Ask ExploreAI a question, and it will <strong>find and summarize</strong> the best Dots for you.`,
  },
  {
    question: "Are all Dots free?",
    answer:
      "No. Some Dots are <strong>free</strong>, while others are priced at <strong>$0.29</strong> or <strong>$0.99 USD</strong>. Paid Dots usually provide <strong>exclusive, high-value insights</strong> from experienced creators.",
  },
  {
    question: "How do I purchase a Dot?",
    answer: `
      • Tap on a <strong>Dot</strong>, and you’ll see a preview.<br>
      • Click <strong>Buy Dot</strong> and complete the purchase through <strong>in-app payment</strong> (Google Play / Apple Store).<br>
      • Once purchased, the Dot is yours <strong>forever</strong>——revisit it anytime!`,
  },
  {
    question: "How does ExploreAI work?",
    answer: `
      ExploreAI is your <strong>personal AI assistant</strong> on VaSi. Instead of manually searching, you can <strong>ask a question in natural language</strong>, and ExploreAI will:<br>
      1. Find the most <strong>relevant Dots</strong> from the marketplace.<br>
      2. Summarize their key insights for you.<br>
      3. Suggest new Dots that match your interests.<br>
      <br>
      💡 <strong>Example:</strong><br>
      If you type "<strong>How do I start meditating?</strong>", ExploreAI will <strong>summarize relevant Dots</strong>, giving you a <strong>quick, actionable answer</strong> while recommending more in-depth content.`,
  },
  {
    question: "How can I save or revisit my purchased Dots?",
    answer:
      "Once you purchase a Dot, it is stored in your <strong>Purchased</strong> section. You can revisit it anytime and set reminders to apply what you’ve learned.",
  },
  {
    question: "Can I share a Dot with someone else?",
    answer:
      "Dots are for <strong>personal use only</strong>, so they cannot be shared or transferred. However, you can <strong>send a link</strong> to it! Your friends can open the link, check out the Dot on VaSi, and get it for themselves if they're interested.",
  },
  {
    question: "Does VaSi offer refunds on purchased Dots?",
    answer:
      "Since Dots are <strong>digital content</strong>, purchases are <strong>non-refundable</strong>. If you face any issues, you can contact <strong>VaSi Support</strong> for assistance.",
  },
  {
    question: "How do I contact support if I have issues?",
    answer:
      `You can reach out via the <strong>"<a href="/contact-us/">Contact Us</a>"</strong> option in the app for any queries related to payments, purchases, or technical issues.`,
  },
];

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <div className="container">
        <h2 className="faq-title">Do you have any questions?</h2>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{faq.question}</span>
                <span className="faq-icon">{openIndex === index ? "−" : "+"}</span>
              </div>
              {openIndex === index && (
                <p
                  className="faq-answer"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
