// blogPosts.js
import blog_img_1 from '../assets/images/blogs/blog_img_1.jfif';
const rawPosts = [
  {   
    title: "VaSi Launch Recap",
    author: "Team VaSi",
    date: "March 25, 2025",
    banner: blog_img_1,
    excerpt: "We had an exciting launch for VaSi. Here's everything you need to know.",
    content: "<p>This is the full HTML content of the blog post...</p>",
    tags: ["launch", "events"]
  },
  {   
    title: "The Future of Learning with VaSi",
    author: "Dr. Asha Rao",
    date: "March 26, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "Explore how VaSi is changing the landscape of education.",
    content: "<p>VaSi enables immersive and intelligent learning journeys...</p>",
    tags: ["education", "future"]
  },
  {    
    title: "Meet Your New AI Companion",
    author: "Team VaSi",
    date: "March 27, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "Introducing VaSi’s AI that acts as your personal mindful assistant.",
    content: "<p>This post walks you through VaSi's AI features and capabilities...</p>",
    tags: ["AI", "companion"]
  },
  {
    title: "How to Stay Focused in a Distracted World",
    author: "VaSi Team",
    date: "March 28, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "Practical tips and how VaSi can support your mindfulness journey.",
    content: "<p>Staying focused is harder than ever. VaSi helps simplify...</p>",
    tags: ["mindfulness", "focus"]
  },
  {
    
    title: "Behind the Scenes: Building VaSi",
    author: "Founder",
    date: "March 29, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "A glimpse into the journey, challenges, and vision behind VaSi.",
    content: "<p>This article dives into the origin and development process...</p>",
    tags: ["startup", "story"]
  },
  {
   
    title: "VaSi User Stories",
    author: "Team VaSi",
    date: "March 30, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "Hear from users how VaSi helped improve their focus and productivity.",
    content: "<p>Real experiences from the VaSi community...</p>",
    tags: ["focus", "story"]
  },
  {
    
    title: "VaSi’s Impact in Education Sector",
    author: "Dr. Arjun Menon",
    date: "March 31, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "VaSi is transforming classrooms with intelligent assistance.",
    content: "<p>Educational institutions are embracing VaSi...</p>",
    tags: ["education", "impact"]
  }
  ,
  {
   
    title: "VaSi’s Impact in Education Sector",
    author: "Dr. Arjun Menon",
    date: "March 31, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "VaSi is transforming classrooms with intelligent assistance.",
    content: "<p>Educational institutions are embracing VaSi...</p>",
    tags: ["education", "impact"]
  }
  ,
  {
    
    title: "VaSi’s Impact in Education Sector",
    author: "Dr. Arjun Menon",
    date: "March 31, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "VaSi is transforming classrooms with intelligent assistance.",
    content: "<p>Educational institutions are embracing VaSi...</p>",
    tags: ["education", "impact"]
  },
  {
    
    title: "VaSi’s Impact in Education Sector",
    author: "Dr. Arjun Menon",
    date: "March 31, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "VaSi is transforming classrooms with intelligent assistance.",
    content: "<p>Educational institutions are embracing VaSi...</p>",
    tags: ["education", "impact"]
  },
  {
   
    title: "VaSi’s Impact in Education Sector",
    author: "Dr. Arjun Menon",
    date: "March 31, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "VaSi is transforming classrooms with intelligent assistance.",
    content: "<p>Educational institutions are embracing VaSi...</p>",
    tags: ["education", "impact"]
  },
  {
    
    title: "VaSi’s Impact in Education Sector-12",
    author: "Dr. Arjun Menon",
    date: "March 31, 2025",
    banner: "https://cdn.pixabay.com/photo/2019/01/28/02/10/girl-taking-photo-3959468_1280.jpg",
    excerpt: "VaSi is transforming classrooms with intelligent assistance.",
    content: "<p>Educational institutions are embracing VaSi...</p>",
    tags: ["education", "impact"]
  },
  {   
    title: "The Knowledge Economy: Why More People Are Monetizing Their Expertise",
    author: "VaSi",
    date: "March 31, 2025",
    banner: blog_img_1,
    excerpt: "We had an exciting launch for VaSi. Here's everything you need to know.",
    content: `<p class="blog-content-header"><em>The World is Changing – So is the Way We Share Knowledge.</em> For centuries, knowledge was locked behind institutions, degrees, and exclusive networks. Then came the internet, unlocking a flood of information—but with a major flaw: the loudest voices, not necessarily the most valuable insights, got the attention. Social media rewarded influencers with massive followings, while real experts were left behind, struggling to get paid for their hard-earned wisdom.</p>

<p><strong>That’s why VaSi is here to change the game.</strong></p>

<div class="toc">
  <h4>Table of Contents</h4>
  <ul>
    <li>1. The Rise of the Knowledge Economy
      <ul>
        <li>The Internet Made Knowledge More Accessible—But Also More Valuable</li>
        <li>Social Media Proves That People Pay for Expertise</li>
        <li>Traditional Career Paths Are No Longer Enough</li>
        <li>People Want to Learn from Individuals, Not Just Institutions</li>
        <li>Technology Makes It Easier Than Ever to Monetize Knowledge</li>
        <li>The Future of Work is Expertise-Driven</li>
      </ul>
    </li>
    <li>2. Why Now is the Best Time to Start Monetizing Your Knowledge</li>
  </ul>
</div>

<h2>1. The Rise of the Knowledge Economy</h2>
<p>The world is shifting. We’ve moved from an industrial economy to a digital economy—and now, we’re entering the <strong>knowledge economy</strong>.</p>
<p>People are no longer just consumers of information; they are <strong>creators of value</strong>. Whether it's life experiences, industry insights, or niche expertise, <strong>knowledge is now a currency</strong>—and more people than ever are turning it into income.</p>
<p>But <strong>why is this happening?</strong> Why are professionals, creators, and everyday experts choosing to <strong>monetize their knowledge instead of giving it away for free</strong>?</p>
<p>Let’s break it down.</p>

<h3>1. The Internet Made Knowledge More Accessible—But Also More Valuable</h3>
<p>With unlimited access to information, you’d think knowledge would lose value. But the opposite is true.</p>
<p>People are drowning in content—but <strong>starving for clarity</strong>. They don’t need more information; they need <strong>the right insights, from the right people, at the right time</strong>.</p>
<p>That’s why experts who can curate, simplify, and deliver actionable knowledge are thriving in the knowledge economy.</p>

<h3>2. Social Media Proves That People Pay for Expertise</h3>
<p>Think about it:</p>
<ul class="no-disc">
  <li>✅ Coaches charging for strategy calls</li>
  <li>✅ Course creators earning six figures</li>
  <li>✅ Experts selling eBooks, templates, and guides</li>
</ul>
<p>People are willing to pay for direct, valuable insights. But until now, these opportunities were mostly limited to influencers and big brands.</p>
<p>The knowledge <strong>economy changes that</strong>—allowing <strong>everyday experts</strong> to earn from their skills, insights, and unique perspectives.</p>

<h3>3. Traditional Career Paths Are No Longer Enough</h3>
<p>The old formula was simple:</p>
<p>➡️ Get a degree → 🎯 Land a job → 💰 Retire comfortably.</p>
<p>But today, things have changed:</p>
<ul>
  <li><strong>Job security is shrinking.</strong></li>
  <li><strong>Wages aren’t keeping up with inflation.</strong></li>
  <li><strong>More people want control over their income.</strong></li>
</ul>
<p>That’s why professionals, freelancers, and even retirees are <strong>leveraging their knowledge</strong> to create additional income streams—without quitting their jobs or investing in expensive business models.</p>

<h3>4. People Want to Learn from Individuals, Not Just Institutions</h3>
<p>Universities, corporations, and training academies used to be the <strong>gatekeepers of knowledge</strong>. Not anymore.</p>
<p>People today prefer <strong>learning from real individuals</strong>—those with hands-on experience and practical insights.</p>
<p>This is why platforms like VaSi exist—to help experts <strong>cut through the noise</strong> and deliver knowledge directly to those who need it.</p>

<h3>5. Technology Makes It Easier Than Ever to Monetize Knowledge</h3>
<p>The rise of <strong>AI, marketplaces, and digital payment systems</strong> has eliminated the barriers that once made monetization difficult.</p>
<p>You no longer need:</p>
<ul class="no-disc">
  <li>❌ A huge marketing budget</li>
  <li>❌ A complicated website</li>
  <li>❌ Thousands of followers</li>
</ul>
<p>Now, platforms like <strong>VaSi</strong> enable you to <strong>package and sell</strong> your insights <strong>instantly</strong>. Whether it’s a life lesson, a business strategy, or a skill you’ve mastered, there’s someone out there willing to pay for it.</p>

<h3>6. The Future of Work is Expertise-Driven</h3>
<p>The future isn’t about <strong>who works the hardest—it’s about who knows the most</strong>.</p>
<p>As automation and AI replace repetitive tasks, <strong>human knowledge and creativity</strong> will become the most <strong>valuable assets</strong>.</p>
<p>The knowledge economy isn’t just an opportunity—it’s a <strong>necessity</strong> for those who want to stay ahead.</p>

<h2>Why Now is the Best Time to Start Monetizing Your Knowledge</h2>
<ul class="no-disc">
  <li>✅ The demand for trusted, real-world insights is skyrocketing.</li>
  <li>✅ The tools to package and sell knowledge are better than ever.</li>
  <li>✅ The market is shifting toward expert-driven economies.</li>
</ul>
<p>The only question is—how will you take advantage of it?</p>
<p>Join the knowledge economy. Start monetizing what you know.</p>
<p><strong><em>Sign up on VaSi today. 🚀</em></strong></p>`,
    tags: ["education", "impact"]
  }
];

// Generate id and slug dynamically
const blogPosts = rawPosts.map((post, index) => ({
  ...post,
  id: index + 1,
  slug: post.title.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/(^-|-$)/g, "")
}));

export default blogPosts;