import React, { useState, useEffect } from 'react';
//import { Link } from "react-router-dom"
import logo_vasi from '../assets/images/landing-page/logo_vasi.png';
import { NavLink } from 'react-router-dom';

function NavBar() {

    const [menuVisible, setMenuVisible] = useState(true);

    useEffect(() => {
        console.log("menuVisible===", menuVisible);
        const checkMobile = () => {
            const mediaQuery = window.matchMedia('(max-width: 1023px)'); // Adjust the media query based on your mobile device requirements
            console.log(mediaQuery.matches);
            if (mediaQuery.matches) {
                setMenuVisible(false);
            } else {
                setMenuVisible(true);
            }

        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const toggleMenu = () => {
        console.log("menuVisible11===", menuVisible);
        setMenuVisible(!menuVisible);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light hidden">
            {/* <div className="header_nav_left"></div> */}

            <a className="navbar-brand" href="/">
                <img width={60} src={logo_vasi} alt="VaSi" />
            </a>
            <a target="_blank" rel="noreferrer" className="btn try-btn-mobile" href="https://learnee.page.link/rniX">Get Started</a>

            {/* <div className="header_nav_right"></div> */}
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarHeader"
                aria-controls="navbarHeader"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span onClick={toggleMenu} className={`navbar-toggler-icon ${menuVisible ? 'cross-icon' : ''}`}></span>
            </button>
            {menuVisible &&
                <div className="collapse navbar-collapse" id="navbarHeader">
                    <ul className="navbar-nav ml-auto" style={{ margin: "auto" }}>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/"
                                className="nav-link"
                                activeClassName="active-link"
                            >
                                Home
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/earn"
                                className="nav-link"
                                activeClassName="active-link"
                            >
                                Earn
                            </NavLink>
                        </li>

                        {/* <li className="nav-item">
                            <NavLink
                                to="/about-us/"
                                className="nav-link"
                                activeClassName="active-link"
                            >
                                About
                            </NavLink>
                        </li> */}

                        {/* <li className="nav-item">
                            <NavLink
                                to="/blogs/"
                                className="nav-link"
                                activeClassName="active-link"
                            >
                                Blogs
                            </NavLink>
                        </li> */}

                        <li className="nav-item">
                            <NavLink
                                to="/contact-us/"
                                className="nav-link"
                                activeClassName="active-link"
                            >
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                    <div className="nav-item">
                        <a target="_blank" rel="noreferrer" className="btn try-btn" href="https://learnee.page.link/rniX">Get Started</a>
                    </div>
                </div>
            }
        </nav>

    )
}

export default NavBar
