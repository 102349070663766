import React from "react";
import FAQSection from './FAQLandingPage';
import FAQCreators from './FAQCreators';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap
import "../assets/css/faqPage.css"; // Custom styling

const FAQPage = () => {
    return (
        <div className="container faq-page mt-5">
            {/* Consumer FAQ Section */}
            <div className="faq-section-consumer faq-section faq-section-creators consumer-page-landing">
                <h2 className="text-center fw-bold mb-4">For Consumers</h2>
                <FAQSection />
            </div>

            {/* Separator with Bootstrap Styling */}
            <div className="faq-separator text-center my-5">
                <div className="separator-content d-flex align-items-center justify-content-center">
                    <div className="line"></div>
                    <h2 className="mx-3">
                        <i className="bi bi-star-fill text-warning"></i> For Creators{" "}
                        <i className="bi bi-star-fill text-warning"></i>
                    </h2>
                    <div className="line"></div>
                </div>
            </div>

            {/* Creator FAQ Section */}
            <div className="faq-section-creators">
                <FAQCreators />
            </div>
        </div>
    );
};

export default FAQPage;
