import React from "react"
import GooglePlayImg from '../assets/images/landing-page/GooglePlay.png';
import AppStoreImg from '../assets/images/landing-page/AppStore.png';
import instagram from '../assets/images/consumer_landing/instagram.png';
import twitter from '../assets/images/consumer_landing/twitter.png';
import linkedin from '../assets/images/consumer_landing/linkedin.png';
import facebook from '../assets/images/consumer_landing/facebook.png';
import logo_vasi from '../assets/images/landing-page/logo_vasi.png';

function LandingPageFooterCosumer() {
    return (
        <div className="footer-container" id="footerContainer2">
        {/* <div className="footer_bg_left"></div>
        <div className="footer_bg_right"></div> */}
        <div className="left-section">

            <div className="footer-heading">
                <img src={logo_vasi} alt="VaSi" width={60} />
            </div>

            <p className="footer-description">Our platform ensures that every insight is preserved and transformed into the guidance you need to move forward.</p>

            <div className="app-buttons">
                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.learnee.vasi"><img src={GooglePlayImg} alt="Google Play" /></a>
                <a href="https://apps.apple.com/us/app/vasi-extend-your-mind/id1488476877" target="_blank" rel="noreferrer"><img src={AppStoreImg} alt="App Store" /></a>
            </div>
        </div>

        <div className="bottom-section">
            <div className="footer-row">

                {/* Copyright Text (First in Desktop, Last in Mobile) */}
                <div>
                    <p className="footer-bottom-text">© {new Date().getFullYear()} All rights reserved by VaSi</p>
                </div>

                {/* Footer Links (Second in Desktop, Middle in Mobile) */}
                <div className="footer-links">
                    <a rel="noreferrer" href="/faq/">FAQ</a>
                    <a rel="noreferrer" href="/privacy-policy-usa/">Privacy Policy</a>
                    <a rel="noreferrer" href="/terms-of-service-usa/">Terms & Conditions</a>
                </div>

                {/* Social Icons (Third in Desktop, First in Mobile) */}
                <div className="footer-social-icons">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/vasi-ai-301267272/">
                        <img src={linkedin} alt="LinkedIn" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/vasi.ai/">
                        <img src={instagram} alt="Instagram" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/VaSi_Ai/">
                        <img src={twitter} alt="X" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100093084038569">
                        <img src={facebook} alt="X" />
                    </a>
                </div>

            </div>
        </div>
    </div>

    );
};

export default LandingPageFooterCosumer;