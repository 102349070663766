import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import logo from '../assets/images/logo.svg';
//import '../assets/css/App.css';
//import { UseAuth } from "../hooks/useAuth";
//import { isMobile } from 'react-device-detect';
import ReactGA from "react-ga4";

// My Components
//import Header from "./Header"
import PrivateRoute from './PrivateRoute'
//import SignInScreen from "./SignIn"
import LoginScreen from "./Login"
//import NotFound from "./NotFound"
import Dashboard from "./Admin/Dashboard"
import DashboardUserProfile from "./Admin/DashboardUserProfile"
import PaymentSuccessAdmin from "./Admin/PaymentSuccessAdmin"
import PaymentFailureAdmin from "./Admin/PaymentFailureAdmin"
import NotFoundAdmin from "./Admin/NotFoundAdmin";
import RedeemedUserLists from "./Admin/RedeemedUserLists"
import MarketPlaceListsAdmin from "./Admin/MarketPlaceListsAdmin"
import ArchivedMarketPlaceListsAdmin from "./Admin/ArchivedMarketPlaceListsAdmin"
import AdminDetailPage from "./Admin/AdminDetailPage"
import AdminNotificationSender from "./Admin/AdminNotificationSender"
import AdminPromotionalNotificationSender from "./Admin/AdminPromotionalNotificationSender"
import ReferralPayoutUserLists from "./Admin/ReferralPayoutUserLists"
//import LandingPage from "./LandingPage"
//import LandingPageNew from './LandingPageNew';
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs"
import TermsUSA from "./Pages/TermsUSA"
import TermsIndia from "./Pages/TermsIndia"
import PrivacyPolicyUSA from "./Pages/PrivacyPolicyUSA"
import PrivacyPolicyIndia from "./Pages/PrivacyPolicyIndia"
import FAQPage from "./Pages/FAQPage"
import GuidelinesAndProcedure from "./Pages/GuidelinesAndProcedure"
import ReferAndEarnTOS from "./Pages/ReferAndEarnTOS"
import CreateShareEarn from "./Pages/CreateShareEarn"
import MarketplaceDrawTos from "./Pages/MarketplaceDrawTos"
import DetailPage from "./DetailPage"
//import DownloadsPage from "./DownloadsPage"
import CreatorsPage from './CreatorsPage';

import ConsumerLandingPage from './ConsumerLandingPage';
import AdminUserList from './Admin/AdminUserList';
//import BlogPage from './Pages/BlogPage';
//import BlogDetailPage from './Pages/BlogDetailPage';
import MetaHandler from './MetaHandler';


function App() {

 

  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  // const PageTitle = () => {
  //   const location = useLocation();

  //   useEffect(() => {
  //     const titles = {
  //       "/": "VaSi – Buy Expert Knowledge & Insights",
  //       "/earn": "VaSi – Earn Money by Sharing Your Knowledge",
  //       "/about": "About Us - Extend Your Mind",
  //       "/contact-us": "Contact Us - VaSi",
  //       "/contact-us/": "Contact Us - VaSi",
  //     };
  //     document.title = titles[location.pathname] || "VaSi - Extend Your Mind";
  //   }, [location]);

  //   return null; // This component doesn't render anything
  // };



  return (
    <BrowserRouter>
      <MetaHandler />
      <div>
        <Routes>

          <Route path="*" element={<NotFoundAdmin />} />

          <Route path='/details'>
            <Route path='/details' element={<DetailPage />} />
          </Route>

          <Route path='/dots'>
            <Route path='/dots' element={<DetailPage />} />
          </Route>


          <Route path='/dashboard' element={<PrivateRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>

          <Route path='/dots-details/:id' element={<PrivateRoute />}>
            <Route path='/dots-details/:id' element={<AdminDetailPage />} />
          </Route>

          <Route path='/dashboard/profile/:userId' element={<PrivateRoute />}>
            <Route path='/dashboard/profile/:userId' element={<DashboardUserProfile />} />
          </Route>

          <Route path='/dashboard/success/' element={<PrivateRoute />}>
            <Route path='/dashboard/success/' element={<PaymentSuccessAdmin />} />
          </Route>

          <Route path='/dashboard/canceled/' element={<PrivateRoute />}>
            <Route path='/dashboard/canceled/' element={<PaymentFailureAdmin />} />
          </Route>

          <Route path='/dashboard/payouts' element={<PrivateRoute />}>
            <Route path='/dashboard/payouts' element={<RedeemedUserLists />} />
          </Route>

          <Route path='/dashboard/referral-payouts' element={<PrivateRoute />}>
            <Route path='/dashboard/referral-payouts' element={<ReferralPayoutUserLists />} />
          </Route>

          <Route path='/dashboard/payouts/:pagenum' element={<PrivateRoute />}>
            <Route path='/dashboard/payouts/:pagenum' element={<RedeemedUserLists />} />
          </Route>

          <Route path='/dashboard/marketplacedots' element={<PrivateRoute />}>
            <Route path='/dashboard/marketplacedots' element={<MarketPlaceListsAdmin />} />
          </Route>

          <Route path='/dashboard/archived-marketplace-dots' element={<PrivateRoute />}>
            <Route path='/dashboard/archived-marketplace-dots' element={<ArchivedMarketPlaceListsAdmin />} />
          </Route>

          <Route path='/dashboard/send-push-notification' element={<PrivateRoute />}>
            <Route path='/dashboard/send-push-notification' element={<AdminNotificationSender />} />
          </Route>

          <Route path='/dashboard/send-promotional-push-notification' element={<PrivateRoute />}>
            <Route path='/dashboard/send-promotional-push-notification' element={<AdminPromotionalNotificationSender />} />
          </Route>

          <Route path='/dashboard/user-lists' element={<PrivateRoute />}>
            <Route path='/dashboard/user-lists' element={<AdminUserList />} />
          </Route>

          <Route path='/' element={<ConsumerLandingPage />} />

          <Route path='/download' element={<CreatorsPage />} />
          <Route path='/creators' element={<CreatorsPage />} />
          <Route path='/earn' element={<CreatorsPage />} />
          <Route path='/discover' element={<ConsumerLandingPage />} />

          <Route path="/login" element={<LoginScreen />} />



          {/* <Route path='/vasipage' element={<LandingPageNew />} /> */}
          <Route path='/contact-us' element={<ContactUs />} />

          <Route path='/about-us' element={<AboutUs />} />
          <Route path="/terms-of-service-usa" element={<TermsUSA />} />
          <Route path="/terms-of-service-india" element={<TermsIndia />} />
          <Route path="/privacy-policy-usa" element={<PrivacyPolicyUSA />} />
          <Route path="/privacy-policy-india" element={<PrivacyPolicyIndia />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/guidelines-and-procedure/" element={<GuidelinesAndProcedure />} />
          <Route path="/guidelines-and-procedure/:country" element={<GuidelinesAndProcedure />} />
          <Route path="/refer-and-earn-tos" element={<ReferAndEarnTOS />} />
          <Route path="/create-share-earn-with-vasi" element={<CreateShareEarn />} />
          <Route path="/marketplace-draw-tos" element={<MarketplaceDrawTos />} />

          {/* <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blogs/:slug" element={<BlogDetailPage />} /> */}

        </Routes>


      </div>
    </BrowserRouter>
  );
}

export default App;
