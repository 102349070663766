import React, { useState } from "react";
import Slider from "react-slick";
import "../assets/css/HowCanLearnSection.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import how_can_create_1 from '../assets/images/landing_v2/how_can_create_1.png';
import how_can_create_2 from '../assets/images/landing_v2/how_can_create_2.png';
import how_can_create_3 from '../assets/images/landing_v2/how_can_create_3.png';

import making_notes from '../assets/images/landing_v2/making_notes.png';
import online_store from '../assets/images/landing_v2/online_store.png';
import euro_money from '../assets/images/landing_v2/euro_money.png';

const HowCanCreateSection = () => {
    const cards = [
        { title: "Share Your Knowledge", text: "Package what you know into bite-sized, actionable content - Dot. No fancy skills needed.", img: how_can_create_1, icon: making_notes },
        { title: "Publish and Refine", text: "See what resonates with your audience—and iterate as you grow.", img: how_can_create_2, icon: online_store },
        { title: "Earn on your Terms", text: "Get paid for every Dot purchase", img: how_can_create_3, icon: euro_money },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1.06, // Shows 1 full card + 10% of the next one
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,
        centerPadding: "5%",
        afterChange: (index) => setActiveIndex(index), // ✅ Track active slide
        responsive: [
            { breakpoint: 768, settings: { slidesToShow: 1.06, centerPadding: "5%" } },
            { breakpoint: 1024, settings: { slidesToShow: 1.3, centerPadding: "15%" } },
        ],
    };

    return (
        <div className="how-can-learn-section text-center">

            <div className="container mt-4" style={{ position: "relative" }}>
                <Slider {...settings}>
                    {cards.map((card, index) => (
                        <div key={index} className="carousel-card">
                            <div className="info-card d-flex">
                                <div className="text-left flex-grow-1">
                                    <div className="info-icon">
                                        <img loading="lazy" src={card.icon} alt={card.title} className="img-fluid" />
                                    </div>
                                    <h3 className="info-title">{card.title}</h3>
                                    <p className="info-text">{card.text}</p>
                                </div>
                                <div className="info-image-container">
                                    <img loading="lazy" src={card.img} alt={card.title} className="img-fluid info-image" />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

                {/* Carousel Indicators */}
                <div className="carousel-indicators">
                    {cards.map((_, index) => (
                        <span
                            key={index}
                            className={`indicator ${activeIndex % cards.length === index ? "active" : ""}`}
                            onClick={() => setActiveIndex(index)} // ✅ Clicking moves to slide
                        ></span>
                    ))}
                </div>

            </div>
            
        </div>
    );
};

export default HowCanCreateSection;
