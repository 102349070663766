import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholder_img from '../assets/images/landing-page/no-image.png';
import noproile_img from '../assets/images/landing-page/no-profile.png';

const DotsCarousel = ({ dotsData }) => {

    const slidesToShow = Math.min(5, dotsData.length);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        gap: 20,
        arrows: false,
        autoplay: true,
        lazyLoad: true,      
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: Math.min(4, dotsData.length),
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: Math.min(4, dotsData.length),
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(4, dotsData.length),
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const App_url = 'https://learnee.page.link/rniX'

    //console.log(dotsData);

    return (
        <div className="similar-dots-container slider-container">
            <Slider {...settings} className="dots-carousel">
                {dotsData.length > 0 && dotsData.map((dot, index) => (

                    <a target='_blank' href={App_url} key={index}>
                        <div className="dot-item" key={index}>
                            {/* Check if dotMedia exists and has at least one item */}
                            {(dot.payload?.dotMedia?.length > 0 || dot.dotMedia?.length > 0) &&
                                (dot.payload?.dotMedia?.[0]?.type === 4 || dot.dotMedia?.[0]?.type === 4 || dot.payload?.dotMedia?.[0]?.type === 0 || dot.dotMedia?.[0]?.type === 0) ? (
                                <img className="dot-image"
                                    src={dot.payload?.dotMedia?.[0]?.thumbUrl || dot.dotMedia?.[0]?.thumbUrl}
                                    alt="Dot Thumbnail"
                                />
                            ) : (
                                <img className="dot-image" src={placeholder_img} alt="Dot Thumbnail" />
                            )}
                            <div className="similar-dots-overlay">
                                <div className="similar-dot-title">{dot.dotTitle || "Untitled Dot"}</div>
                                <div className="similar-dot-creator">
                                    {dot.payload?.userAvatar || dot.userAvatar ? (<img className="similar-dot-user-avatar" src={dot.payload?.userAvatar || dot.userAvatar} alt="Dot Thumbnail" />
                                    ) : (
                                        <img className="similar-dot-user-avatar" src={noproile_img} alt="Dot Thumbnail" />
                                    )}&nbsp; {dot.creator || "Unknown Creator"}
                                </div>
                                {/* <div className="similar-dot-creator"> */}
                                {/* <span className='font-weight-600'>&nbsp;{typeof dot.price === 'undefined' ? 'Free' : freeDots(dot.price)}&nbsp;</span> */}
                                {/* {dot.payload?.userAvatar || dot.userAvatar ? (<img className="similar-dot-user-avatar" src={dot.payload?.userAvatar || dot.userAvatar} alt="Dot Thumbnail" />
                                    ) : (
                                        <img className="similar-dot-user-avatar" src={noproile_img} alt="Dot Thumbnail" />
                                    )}&nbsp; {dot.creator || "Unknown Creator"} */}
                                {/* </div> */}
                            </div>
                        </div>
                    </a>

                    // <div className="dot-item" key={index}>
                    //     <img
                    //         src={dot.image}
                    //         alt={dot.title}
                    //         className="dot-image"
                    //     />
                    //     <div className="similar-dots-overlay">
                    //         <div className="similar-dot-title">{dot.title || "Untitled Dot"}</div>
                    //         <div className="similar-dot-creator">
                    //             <img className="similar-dot-user-avatar" src={placeholder_img} alt="Dot Thumbnail" />
                    //             &nbsp;{dot.author}
                    //         </div>
                    //     </div>


                    // </div>
                ))}
            </Slider>
        </div>
    );
};

export default DotsCarousel;