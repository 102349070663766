import React from "react";
import { Modal, Button } from "react-bootstrap";

const ActionModal = ({ item, index, handlers, state, show, onClose, resetState }) => {
    const {
        toggleDisable,
        togglePopularity,
        toggleFeatured,
        togglePromoted,
        generateDynamicLink,
        copyToClipboard,
    } = handlers;
    const {
        isButtonDisabledDots,
        isButtonDisabledPopular,
        isButtonDisabledFeatured,
        isButtonDisabledPromoted,
        isButtonDisabledDynamicLink,
        dynamicLinks,
        successStates,
    } = state;

    // Function to reset the state
    const handleClose = () => {
        resetState(); // Reset the state when the modal closes
        onClose(); // Close the modal
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Actions for {item.mktPlaceId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button
                    className={`w-100 mb-3 ${isButtonDisabledDots[index] ? "loader-btn" : ""}`}
                    variant={`${item.isActive ? "danger" : "primary"}`}
                    onClick={() => toggleDisable(item.mktPlaceId, !item.isActive, index)}
                >
                    {item.isActive ? "Disable Dots" : "Enable Dots"}
                </Button>

                {item.isActive && (
                    <>
                        <Button
                            className={`w-100 mb-3 ${isButtonDisabledPopular[index] ? "loader-btn" : ""}`}                           
                            variant={
                                successStates.populared[index] === true
                                    ? "success"
                                    : successStates.populared[index] === false
                                        ? "danger"
                                        : item.isPopular
                                            ? "danger"
                                            : "primary"
                            }
                            disabled={successStates.populared[index] === true || successStates.populared[index] === false}
                            onClick={() => togglePopularity(item.mktPlaceId, !item.isPopular, index)}
                        >
                            {successStates.populared[index] === true
                                ? "Populared Successfully"
                                : successStates.populared[index] === false
                                    ? "UnPopulared"
                                    : item.isPopular
                                        ? "Remove Popular"
                                        : "Set as Popular"}
                        </Button>
                        <Button
                            className={`w-100 mb-3 ${isButtonDisabledFeatured[index] ? "loader-btn" : ""}`}
                            variant={
                                successStates.featured[index] === true
                                    ? "success"
                                    : successStates.featured[index] === false
                                        ? "danger"
                                        : item.isFeatured
                                            ? "danger"
                                            : "primary"
                            }
                            disabled={successStates.featured[index] === true || successStates.featured[index] === false}
                            onClick={() => toggleFeatured(item.mktPlaceId, !item.isFeatured, index)}
                        >
                            {successStates.featured[index] === true
                                ? "Featured Successfully"
                                : successStates.featured[index] === false
                                    ? "Unfeatured"
                                    : item.isFeatured
                                        ? "Remove Featured"
                                        : "Set as Featured"}
                        </Button>

                        {item.price > 0 && (
                            <Button
                                className={`w-100 mb-3 ${isButtonDisabledPromoted[index] ? "loader-btn" : ""}`} 
                                variant={
                                    successStates.promoted[index] === true
                                        ? "success"
                                        : successStates.promoted[index] === false
                                            ? "danger"
                                            : item.isPromoted
                                                ? "danger"
                                                : "primary"
                                }
                                disabled={successStates.promoted[index] === true || successStates.promoted[index] === false}
                                onClick={() => togglePromoted(item.mktPlaceId, !item.isPromoted, index)}
                            >
                                {successStates.promoted[index] === true
                                    ? "Promoted Successfully"
                                    : successStates.promoted[index] === false
                                        ? "UnPromoted"
                                        : item.isPromoted
                                            ? "Remove Promoted Dot"
                                            : "Promote Dot"}                               
                            </Button>
                        )}
                    </>
                )}

                {dynamicLinks[index] ? (
                    <Button
                        className="w-100 mb-3"
                        variant="success"
                        onClick={() => copyToClipboard(dynamicLinks[index])}
                    >
                        Copy Link
                    </Button>
                ) : (
                    <Button
                        className={`w-100 mb-3 ${isButtonDisabledDynamicLink[index] ? "loader-btn" : ""}`}
                        variant="primary"
                        onClick={() => generateDynamicLink(item.mktPlaceId, index)}
                    >
                        Generate Link
                    </Button>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActionModal;

