import React, { useState, useEffect } from 'react';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import Axios from "axios"
//import Page from "./Page"
//import HeaderLoggedOut from '../HeaderLoggedOut'
//import FooterNew from '../FooterNew'
import NavBar from '../NavBar';
import LandingPageFooter from '../LandingPageFooter';
function ContactUs() {
    //console.log(props);
    const auth = getAuth();
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        message: ""
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [reCaptcha, setRecaptcha] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorResponse, setErrorResponse] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        //console.log(auth.currentUser);
        // if (auth.currentUser === null) {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-feedback-contactus', {
            'size': 'visible',
            'callback': () => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                //this.onSignInSubmit();
                setRecaptcha(true)
                //setRecaptchaErr(false)
                //console.log(response);
            }
        }, auth);

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        }).catch((error) => {
            console.log(error);
        });
        //}


    }, [auth])


    const handleInputChange = (e) => {
        setErrors('')
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const validateForm = () => {

        let fields = values;
        let errors = {};
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter your Name.";
        }


        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "An email is required.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid Email.";
            }
        }

        /*if (!fields["phone"]) {
          formIsValid = false;
          errors["phone"] = "A phone number is required.";
        }*/

        /*if (typeof fields["phone"] !== "undefined") {
          if (!fields["phone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["phone"] = "*Please enter valid mobile no.";
          }
        }*/

        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Please enter some feedback.";
        }

        if (reCaptcha === false) {
            formIsValid = false;
            errors["recaptcha"] = "Solve the reCAPTCHA.";
        }

        setErrors(errors);
        //console.log(errors.name);
        return formIsValid;

    }

    const submitFeedback = (e) => {
        console.log(e);
        e.preventDefault()
        if (validateForm()) {
            setButtonDisabled(true)
            const URL = process.env.REACT_APP_BACKENDURL + 'contactSupport';
            const ourRequest = Axios.CancelToken.source()
            const submitData = async () => {
                try {
                    const response = await Axios.post(URL, {
                        data: {
                            "name": values.name,
                            "email": values.email,
                            "phone": values.phone,
                            "message": values.message
                        }
                    },
                        { cancelToken: ourRequest.token });
                    const json = await response.data;
                    if (json.result.status === 200) {
                        setSuccess(true)
                        setValues(initialValues)
                        setButtonDisabled(false)
                    } else {
                        setErrorResponse(true)
                        setButtonDisabled(false)
                    }

                } catch (e) {
                    setButtonDisabled(false)
                    console.log("There was a problem or the request was cancelled.")
                    //setIsLoading(false);
                }
            }
            submitData();
        }
    }

    //console.log(errors);
    return (
        <div className=" askvasi-home inner_pages_nav elementor-default elementor-kit-6677 elementor-page elementor-page-4722">
            <NavBar />

            <div className="container outer-page contact-us-container">
                <header className="masthead_inner">
                    <div className="contact-us-header">
                        Get Started
                    </div>
                </header>


                {/* <h2 className="contact-header">Get Started</h2> */}
                <div className="contact-title">
                    Get in touch with us. We're here
                    to assist you.
                </div>

                {success ? (
                    <div className="text-center mb-3">
                        <div className="alert alert-success">Your feedback has been submitted successfully.</div>
                    </div>
                ) :

                    <form className="contact-form">
                        <div className="input-row">
                            <div className="contact-us-input-field-wrapper">
                                <input value={values.name} onChange={handleInputChange} name="name" id="name" type="text" className="input-field" placeholder="Your Name" data-sb-validations="required" autoComplete='off' />
                                {errors.name &&
                                    <div className="invalid-feedback-contact-us" data-sb-feedback="name:required">{errors.name}</div>
                                }
                            </div>
                            <div className="contact-us-input-field-wrapper">
                                <input value={values.email} onChange={handleInputChange} name="email" id="email" type="email" className="input-field" placeholder="Email Address" data-sb-validations="required,email" autoComplete='off' />
                                {errors.email &&
                                    <div className="invalid-feedback-contact-us" data-sb-feedback="email:required">{errors.email}</div>
                                }
                            </div>
                            <div className="contact-us-input-field-wrapper">
                                <input onChange={(e) => {
                                    const value = e.target.value.replace(/[^\d\s()+-]/g, ''); // Removes invalid characters
                                    handleInputChange({ target: { name: 'phone', value } });
                                }} value={values.phone} name="phone" id="phone" type="tel" className="input-field" placeholder="Phone Number (optional)" />
                            </div>
                        </div>

                        <textarea value={values.message} onChange={handleInputChange} maxLength="300" name="message" className="input-message" id="message" type="text" placeholder="Enter your message here..." data-sb-validations="required" autoComplete='off'></textarea>

                        {/* <textarea value={values.message} onChange={handleInputChange} className="input-message" placeholder="Message" data-sb-validations="required" autoComplete='off'></textarea> */}
                        {errors.message &&
                            <div className="invalid-feedback-contact-us" data-sb-feedback="message:required">{errors.message}</div>
                        }
                        <div className="form-floating mb-3">
                            <div id='recaptcha-container-feedback-contactus'></div>
                            <div className="invalid-feedback-contact-us" data-sb-feedback="message:required">{errors.recaptcha}</div>
                        </div>

                        {errorResponse && (
                            <div id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending feedback!</div></div>
                        )}

                        <div style={{ marginBottom: "20px" }}>
                            <button disabled={isButtonDisabled} onClick={submitFeedback} type="submit" className="submit-button-contact-us">
                                Leave us a Message <span className="arrow">→</span>
                            </button>
                        </div>
                    </form>
                }
            </div>
            <LandingPageFooter />
        </div>






    );
}

export default ContactUs;