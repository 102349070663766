import React, { useEffect, useState, useRef } from 'react';
import ReactGA from "react-ga4";
import NavBar from './NavBar'


import '../assets/css/consumersLandingPage.css';
import '../assets/css/consumers.css';


import ratings from '../assets/images/consumer_landing/ratings.png';


import omninotes from '../assets/images/consumer_landing/omninotes.png';
import handshake from '../assets/images/consumer_landing/handshake.png';
import money from '../assets/images/consumer_landing/money.png';

import calender_icon from '../assets/images/consumer_landing/calender_icon.png';
import wisdom_icon from '../assets/images/consumer_landing/wisdom_icon.png';
import book_icon from '../assets/images/consumer_landing/book_icon.png';


//import ExploreDotCategory from './ExploreDotCategory';
import HeroSectionAnimation from './HeroSectionAnimation';
import LandingPageFooterCosumer from './LandingPageFooterCosumer';
import ExploreDotCategory from './ExploreDotCategory';
import HowCanLearnSection from './HowCanLearnSection';
import TestimonialsSection from './Testimonials';
import FAQSection from './FAQLandingPage';

//import TextSlider from './TextSlider';

// App Component
function ConsumerLandingPage() {

    const [selectedCategory, setSelectedCategory] = useState("");
    const exploreCategoryRef = useRef(null); // Reference to scroll to

    const App_url = 'https://learnee.page.link/rniX'

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.classList.remove('hidden');
        }
    }, []);


    const handleButtonClick = (action, label) => {
        // Track the button click event
        ReactGA.event({
            category: "Button",
            action: action, //"Download Now",
            label: label //"VaSi App Link",
        });
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        //console.log(selectedCategory);
        // Smooth scroll to category section
        setTimeout(() => {
            exploreCategoryRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 300);
    };


    return (
        <div className="askvasi-home landing-page download-page consumer-page-landing" id='askvasi-landing'>
            {/* Navbar */}
            <NavBar />

            {/*Hero and beyond */}
            <div className="slide-up-overlay-slide-6 page-6 active" id="page-6">
                {/* Container for the two columns */}

                <div className="hero-section-container">
                    {/* Left Column: Overlay content */}
                    <div className="overlay-content-hero-section">

                        <div className='hero-title'><div className='hero-title-1'>Learn From<br /><i>Real Experiences</i><br />Every Day</div></div>
                        <p>Discover micro-learnings in form of Dots on<br /><span className='self-help-btn' onClick={() => handleCategoryClick("Self-Help")}>Self-Help</span>, <span className='nutrition-btn' onClick={() => handleCategoryClick("Health and Nutrition")}>Nutrition</span>,  <span className='fitness-btn' onClick={() => handleCategoryClick("Self-help books")}>Books</span> and <strong>many more</strong> from those who’ve been in your shoes.</p>
                        <div className='hero-section-btn'>
                            <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Get Started", "VaSi App Link")} className="cta-button">Get Started</button></a>
                        </div>
                    </div>

                    {/* Right Column: Blurred images container blurred-images-container-hero-section*/}
                    <div className="blurred-images-container-hero-section">
                        <div className="image-5">
                            <HeroSectionAnimation />
                        </div>
                    </div>

                </div>
            </div>

            {/* Best-lessons Section */}
            <section className="text-center best-lessons">
                <div className="advice-heading mt-3">Life’s Best Lessons, All in One Place</div>
                <div className="d-flex justify-content-center gap-5 my-4 flex-wrap">
                    <div className="lesson-card d-flex align-items-start">
                        <img className="lesson-icon" src={wisdom_icon} loading="lazy" alt="Real user wisdom" />
                        <div className='lesson-card-1'>
                            <span className="testimonials-title">Real user generated wisdom</span>
                            <p className="testimonials-text">
                                Experience real insights from real users, boost your growth with genuine quality content.
                            </p>
                        </div>
                    </div>
                    <div className="lesson-card d-flex align-items-start">
                        <img className="lesson-icon" src={book_icon} loading="lazy" alt="Knowledge hub" />
                        <div className='lesson-card-2'>
                            <span className="testimonials-title">A true knowledge hub</span>
                            <p className="testimonials-text">
                                Explore a plethora of categories of content, all curated by a community passionate about real-life experiences.
                            </p>
                        </div>
                    </div>
                    <div className="lesson-card d-flex align-items-start">
                        <img className="lesson-icon" src={calender_icon} loading="lazy" alt="Actionable learning" />
                        <div className='lesson-card-3'>
                            <span className="testimonials-title">Actionable learning & accountability</span>
                            <p className="testimonials-text">
                                Turn learnings into actions with daily reminders which also keep you accountable.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='best-lessons-btn'>
                    <a rel="noopener noreferrer" href={App_url}>
                        <button onClick={() => handleButtonClick("Get Started", "VaSi App Link")} className="cta-button">Get Started</button></a>
                </div>
            </section>

            <section className="testimonials-section text-center">

                <p className="advice-paragraph advice-paragraph-mobile mt-2">“<span>Best Knowledge app</span> for real experiences, insights, and tips. Truly gem if looking for some real experiences & actionable insights.”</p>
                <div><img className='ratings-icon' src={ratings} loading="lazy" alt="Blurred Dots 5" /></div>
                <p className='advice-paragraph-name'>Isha G.</p>
                <div className="testimonials-grid justify-content-center gap-5 my-4 ">
                    <div>
                        <div><img className='fire-icon' src={handshake} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">10K+</span>
                        <p className="testimonials-text">Users learned and benefited so far</p>
                    </div>
                    <div>
                        <div><img className='list-icon' src={money} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">5K+</span>
                        <p className="testimonials-text">Dots Selling Everyday</p>
                    </div>
                    <div>
                        <div><img className='list-icon' src={omninotes} loading="lazy" alt="Blurred Dots 5" /></div>
                        <span className="testimonials-number">10K+</span>
                        <p className="testimonials-text">Collection of Dots on different categories</p>
                    </div>
                </div>
            </section>


            <div className="download-page consumer-page" ref={exploreCategoryRef}>
                <section className="explore-dots-section">
                    <div className="section-title">Dots for Every Aspect of Life</div>
                    <p className="section-subtitle">
                        Our marketplace isn’t limited to one field—tap into expert insights on a wide array of topics:
                    </p>

                    <ExploreDotCategory selectedCategoryProps={selectedCategory} exploreCategoryRef={exploreCategoryRef} />

                    <div className='best-lessons-btn'>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Get Started", "VaSi App Link")} className="cta-button">
                                Get Started
                            </button>
                        </a>
                    </div>
                </section>
            </div>


            <HowCanLearnSection />

            <TestimonialsSection />

            <div className='container how-to-create'>


                <div className="explore-dots-section ready-to-unlock">

                    <div className="section-title">Learn Anything – Your Dot, Your Way</div>
                    <div className="section-sub-title">From guides to personal stories, insights to how-to lists—learn anything, anytime and anywhere.</div>

                    <div className="carousel-container">

                        <div className="view-all">
                            <a rel="noopener noreferrer" href={App_url}>
                                <button onClick={() => handleButtonClick("Explore Marketplace", "VaSi App Link")} className='cta-button'>Get Started</button>
                            </a>
                        </div>

                    </div>
                    <div className='footer_bg_middle'></div>

                </div>

            </div>


            <FAQSection />

            <LandingPageFooterCosumer />

        </div>

    );
}
export default ConsumerLandingPage;