import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import DashboardHeader from "./DashboardHeader";
import DashboardFooter from "./DashboardFooter";
import LoadingDotsIcon from "../LoadingDotsIcon";
import ExportCSVModal from './ExportUserCSVModal';
import { Button } from "react-bootstrap";
//import Papa from "papaparse";

function AdminUserList() {
    const [users, setUsers] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const[showExportModal, setShowExportModal] = useState(false);
    const getUsersApiUrl = process.env.REACT_APP_BACKENDURL + 'getUserProfileInfo'; // Firebase Function

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async (token = null, isSearch = false) => {
        if (isSearch) {
            setUsers([]);          // ✅ Clear old list
            setNextPageToken(null);
            setHasMore(true);
        }

        setIsLoading(true);
        try {
            const response = await Axios.post(getUsersApiUrl, {
                data: {
                    pageToken: token || null,
                    search: searchQuery,
                    request_type: "getAllUsersForAdmin"
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('userAuth')
                }
            });

            const json = response.data.result;
            if (json.status === 200) {
                setUsers(prev => isSearch ? json.users : [...prev, ...json.users]);
                setNextPageToken(json.nextPageToken);
                setHasMore(!!json.nextPageToken);
            }

            setIsLoading(false);
        } catch (err) {
            console.error("User fetch failed:", err);
            setIsLoading(false);
        }
    };



    const exportToCSV = () => {
        // const csv = Papa.unparse(users.map(user => ({
        //   fuid: user.fuid,
        //   identifier: user.identifier,
        //   created_on: user.created_on,
        //   ...user.firestore
        // })));

        // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(blob);
        // link.download = "admin_users.csv";
        // link.click();
    };

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light userlist">
            <DashboardHeader />

            <div className='container-fluid d-flex justify-content-between align-items-center my-3'>
                {/* <div className="admin-title"><h4>All Users</h4></div> */}

                <div className="ms-3">
                    <Button variant="primary" onClick={() => setShowExportModal(true)}>
                        Export CSV
                    </Button>

                    <ExportCSVModal
                        show={showExportModal}
                        onHide={() => setShowExportModal(false)}  // Close modal when clicked on close button
                        
                    />

                </div>


                <form className='me-xxl-3 admin-search' onSubmit={(e) => { e.preventDefault(); fetchUsers(null, true); }}>
                    <div className="input-group">
                        <input className="form-control py-2 border-right-0 border searchBox" placeholder="Search by FUID or phone" onChange={(e) => setSearchQuery(e.target.value)} type="text" id="srch-term" value={searchQuery} />
                    </div>
                    <span className="margin-left-10">
                        <button className="btn btn-primary border-left-0" type="submit">
                            <i className="fa fa-search"></i>
                        </button>
                    </span>
                </form>


            </div>

            <div className="card-body">
                <div className="table-responsive">
                    {isLoading && users.length === 0 && <LoadingDotsIcon />}

                    <InfiniteScroll
                        dataLength={users.length}
                        next={() => fetchUsers(nextPageToken)}
                        hasMore={hasMore}
                        loader={
                            <div className="text-center my-3">
                                <span>Loading more data...</span>
                            </div>
                        }
                    >
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>FUID</th>
                                    <th>Phone</th>
                                    <th>Created On</th>
                                    <th>Name</th>
                                    <th>Platform</th>
                                    {/* Add more fields if needed */}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, idx) => (
                                    <tr key={idx}>
                                        <td>{user.fuid}</td>
                                        <td>{user.identifier}</td>
                                        <td>{user.created_on}</td>
                                        <td>{user.users_data?.name || '-'}</td>
                                        <td>{user.users_data?.platform || '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {!isLoading && users.length === 0 &&
                            <div className="text-center mt-4">No Users Found.</div>
                        }
                    </InfiniteScroll>
                </div>
            </div>

            <DashboardFooter />
        </div>
    );
}

export default AdminUserList;
