import React from "react";
import '../../assets/css/MarketplaceDrawTos.css';

const MarketplaceDrawTos = () => {

  return (
    <div className="askvasi-home tos-buyer">

      <div className="container outer-page">
        <header className="">
          <div className="container">
            <h1>Buyer Incentive Program (Terms of Service)</h1>
          </div>
        </header>
        <aside className="inner_pages">
          <div className="container">
            <div className="row gx-5">
              <div className="section">
                <h2>Eligibility:</h2>
                <ul>
                  <li>The program is available to all registered VaSi users based in India, United States, and Canada</li>
                  <li>Participants must be at least 12 years of age at the time of participation</li>
                  <li>Only dots marked with "Buy & Win" are eligible for this program</li>
                  <li>Purchases must be made using a valid payment method through the VaSi platform</li>
                </ul>
              </div>

              <div className="section">
                <h2>Program Details:</h2>
                <ul>
                  <li>One winner will be randomly selected each day to receive $1 in their VaSi wallet</li>
                  <li>One weekly winner will be randomly selected to receive $10 in their VaSi wallet</li>
                  <li>Winners are selected from users who purchase "Buy & Win" dots during the respective period</li>
                  <li>Selection of winners is at the sole discretion of Learnee Inc.</li>
                  <li>A qualifying dot must be marked as "Buy & Win" and is determined by Learnee Inc. based on content quality and value to buyers</li>
                </ul>
              </div>

              <div className="section">
                <h2>Payment:</h2>
                <ul>
                  <li>Rewards will be added directly to the winner's VaSi wallet</li>
                  <li>Winners can transfer funds to their designated Payout account</li>
                  <li>All rewards are subject to applicable taxation under local tax laws</li>
                </ul>
              </div>

              <div className="section">
                <h2>Prohibited Activities:</h2>
                <ul>
                  <li>Refunds are not permitted on purchased dots</li>
                  <li>Any attempt to manipulate the selection process or create multiple accounts will result in disqualification</li>
                  <li>Fraudulent activities will lead to immediate program disqualification and may result in account suspension</li>
                </ul>
              </div>

              <div className="section">
                <h2>Termination and Changes:</h2>
                <ul>
                  <li>Learnee Inc. reserves the right to modify, suspend, or terminate the Buyer Incentive Program at any time without prior notice</li>
                  <li>Program rules and reward amounts may be changed at Learnee Inc.'s sole discretion</li>
                </ul>
              </div>

              <div className="section">
                <h2>Governing Law:</h2>
                <ul>
                  <li>These terms shall be governed by and construed in accordance with applicable federal laws and:
                    <ul>
                      <li>For users in the United States: The laws of the state where Learnee Inc. is incorporated</li>
                      <li>For users in Canada: Applicable Canadian federal and provincial laws</li>
                      <li>For users in India: Applicable Indian laws</li>
                    </ul>
                  </li>
                  <li>Any disputes shall be subject to the exclusive jurisdiction of the courts in the respective countries</li>
                </ul>
              </div>


              <div className="section">
                <h2>FAQs:</h2>

                <div className="faq-item">
                  <p className="faq-question">How do I participate in the Buyer Incentive Program?</p>
                  <p>Simply purchase any dot marked with "Buy & Win" on VaSi. Each qualifying purchase automatically enters you into both the daily $1 and weekly $10 draws. No additional registration is required.</p>
                </div>

                <div className="faq-item">
                  <p className="faq-question">How do I identify eligible dots for this program?</p>
                  <p>Look for the "Buy & Win" mark on dots. Only dots with this designation are eligible for the Buyer Incentive Program.</p>
                </div>

                <div className="faq-item">
                  <p className="faq-question">What makes a dot "qualifying" for the program?</p>
                  <p>A qualifying dot must be marked as "Buy & Win" and provide meaningful value to buyers, as determined by Learnee Inc. This includes high-quality content, well-structured tips, comprehensive self-help guides, and detailed to-do lists.</p>
                </div>

                <div className="faq-item">
                  <p className="faq-question">When and how will I know if I've won?</p>
                  <p>Winners are selected daily and weekly. If you win, you'll receive a notification through the VaSi platform, and the reward will be automatically added to your VaSi wallet.</p>
                </div>

                <div className="faq-item">
                  <p className="faq-question">How can I withdraw my rewards?</p>
                  <p>You can transfer money from your VaSi wallet to your designated Payout account at any time. The transfer process is simple and can be done through your account settings.</p>
                </div>

                <div className="faq-item">
                  <p className="faq-question">Can I win multiple times?</p>
                  <p>Yes! Each qualifying purchase of a "Buy & Win" dot gives you a new chance to win, and there's no limit to how many times you can win either the daily or weekly rewards.</p>
                </div>

                <div className="faq-item">
                  <p className="faq-question">Are the rewards taxable?</p>
                  <p>Yes, all rewards may be subject to applicable taxes in your jurisdiction. Please consult your tax advisor for specific guidance.</p>
                </div>
              </div>

            </div>
          </div>
        </aside>
      </div>

    </div>
  );
};

export default MarketplaceDrawTos;
